const PageInfo = {
  path: {
    root: "/",
    occupation: {
      input: "/occupation/input/",
      plan: "/occupation/plan/",
      cancel: "/occupation/cancel/",
    },
    application: {
      preConfirm: "/application/preConfirm/",
      input: "/application/input/",
      notify: "/application/notify/",
      confirm: "/application/confirm/",
      complete: "/application/complete/",
      payment: "/application/payment/ "
    },
  },
};
export default PageInfo;
