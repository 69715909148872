import { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageInfo from "./common/constant/PageInfo";
import PassedPageManagementUtil from "./common/util/PassedPageManagementUtil";
import { Provider } from "./common/service/Context";
import {
  getDataForApplicationPreConfirm,
  getDataForOccupationInput,
  getDataForOccupationPlan,
  getDataForApplcationInput,
  getDataForApplicatonConfirm,
  getDataForNotify,
  getCustomerForHeaderFooter,
  getProductForHeader,
  getSalesForHeader,
} from "./common/service/AipomApiService";
import { getErrorContext } from "./common/context/errorHandlingContext";
import BaseModal from "./component/ui/base/BaseModal";
import Header from "./component/common/Header";
import Footer from "./component/common/Footer";
import PreConfirm from "./component/application/PreConfirm";
import OccupationInput from "./component/occupation/OccupationInput";
import OccupationPlan from "./component/occupation/OccupationPlan";
import OccupationCancel from "./component/occupation/OccupationCancel";
import ApplicationInput from "./component/application/ApplicationInput";
import Notify from "./component/application/Notify";
import PaymentInput from "./component/application/PaymentInput";
import ApplicationConfirm from "./component/application/ApplicationComfirm";
import Complete from "./component/application/Complete";
import Error from "./component/common/Error";

const App = () => {
  const [currentPage, setCurrentPage] = useState<string>();
  const [pageData, setPageData] = useState<any>({});
  const [customerInfoForHeaderFooter, setCustomerInfoForHeaderFooter] =
    useState<any>({});
  const [productNameForHeader, setProductNameForHeader] = useState<string>("");
  const [dateForHeader, setDateForHeader] = useState<any>({});
  const queryString = window.location.search;
  const saleId = queryString.replace("?saleId=", "");

  const errorContext = getErrorContext();
  const [isCommonModalOpened, setIsCommonModalOpened] = useState<boolean>(
    errorContext.isCommonModalOpened
  );
  errorContext.setCommonModalOpened = () =>
    setIsCommonModalOpened(errorContext.isCommonModalOpened);

  useEffect(() => {
    if (queryString && currentPage) {
      getDataForCurrentPage();

      // 初期データ非同期取得
      getCustomerInfo();
      getProductName();
      getSecurityStartAt();
    }

    if (window.location.pathname === "/error") {
      getCustomerInfo();
      getProductName();
      getSecurityStartAt();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const getCustomerInfo = async () => {
    const customer = (await getCustomerForHeaderFooter(saleId)) as any;
    setCustomerInfoForHeaderFooter(customer);
  };

  const getProductName = async () => {
    const productName = (await getProductForHeader(saleId)) as any;
    setProductNameForHeader(productName);
  };

  const getSecurityStartAt = async () => {
    const securityStartAt = (await getSalesForHeader(saleId)) as any;
    setDateForHeader(securityStartAt);
  };

  /**
   * 現在のページで必要なデータを取得
   */
  const getDataForCurrentPage = async () => {
    if (
      currentPage !== PageInfo.path.application.preConfirm &&
      PassedPageManagementUtil.isEmptypPassedPageList()
    ) {
      window.location.replace("/error" + saleId);
    }
    let currentPageData = {};

    switch (currentPage) {
      case PageInfo.path.application.preConfirm:
        currentPageData = await getDataForApplicationPreConfirm(saleId);
        break;
      case PageInfo.path.occupation.input:
        currentPageData = await getDataForOccupationInput(saleId);
        break;
      case PageInfo.path.occupation.plan:
        currentPageData = await getDataForOccupationPlan(saleId);
        break;
      case PageInfo.path.application.input:
        currentPageData = await getDataForApplcationInput(saleId);
        break;
      case PageInfo.path.application.notify:
        currentPageData = await getDataForNotify(saleId);
        break;
      case PageInfo.path.application.confirm:
        currentPageData = await getDataForApplicatonConfirm(saleId);
        break;
    }

    setPageData(currentPageData);
  };

  return (
    <Router>
      <Provider>
        <main className="container">
          {new Date(dateForHeader.endAt) > new Date() && (
            <>
              <Header
                data={{
                  customerName: customerInfoForHeaderFooter.customerName,
                  productName: productNameForHeader,
                  securityStartAt: dateForHeader.securityStartAt,
                }}
              />
            </>
          )}
          <Switch>
            <Route
              exact
              path={[PageInfo.path.root, PageInfo.path.application.preConfirm]}
              render={(props) => (
                <PreConfirm
                  {...props}
                  data={pageData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            />
            <Route
              exact
              path={PageInfo.path.occupation.input}
              render={(props) => (
                <OccupationInput
                  {...props}
                  data={pageData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            />
            <Route
              exact
              path={PageInfo.path.occupation.plan}
              render={(props) => (
                <OccupationPlan
                  {...props}
                  data={pageData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            />
            <Route
              exact
              path={PageInfo.path.occupation.cancel}
              render={(props) => (
                <OccupationCancel {...props} currentPage={currentPage} />
              )}
            />

            <Route
              exact
              path={PageInfo.path.application.input}
              render={(props) => (
                <ApplicationInput
                  {...props}
                  data={pageData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            />
            <Route
              exact
              path={PageInfo.path.application.notify}
              render={(props) => (
                <Notify
                  {...props}
                  data={pageData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            />
            <Route
              exact
              path={PageInfo.path.application.payment}
              render={(props) => (
                <PaymentInput
                  {...props}
                  data={pageData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            />
            <Route
              exact
              path={PageInfo.path.application.confirm}
              render={(props) => (
                <ApplicationConfirm
                  {...props}
                  data={pageData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            />
            <Route
              exact
              path={PageInfo.path.application.complete}
              render={(props) => (
                <Complete {...props} setCurrentPage={setCurrentPage} />
              )}
            />
            <Route
              render={(props) => <Error {...props} currentPage={currentPage} />}
            />
          </Switch>
        </main>
        <div className="row">
          {new Date(dateForHeader.endAt) > new Date() && (
            <>
              <Footer data={customerInfoForHeaderFooter} />
            </>)}
        </div>
        <section>
          <BaseModal
            isOpen={isCommonModalOpened}
            setIsOpen={setIsCommonModalOpened}
          >
            <h4>{errorContext.code}</h4>
            <h4>{errorContext.message}</h4>
            <button className="button-common-blue mgt36" onClick={() => setIsCommonModalOpened(false)}>
              閉じる
            </button>
          </BaseModal>
        </section>
      </Provider>
    </Router>
  );
};

export default App;
