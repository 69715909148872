import { useContextState } from "../../common/service/Context";

/**
 * 汎用エラー画面
 *
 * @param props
 * @returns
 */
const Error = (props: any) => {
  const state = useContextState();

  /**
   * 前の画面に戻る
   */
  const onClickPreviousPage = () => {
    if (props.currentPage && state.saleId) {
      props.history.push(props.currentPage + state.saleId);
    } else {
      window.history.back();
    }
  };
  return (
    <article>
      <section className="main-container">
        <h2 className="font-color-blue">エラー</h2>
        <div className="round-area-smooth container-common-border-smooth">
          システムエラーが発生しました。
          前の画面に戻るか、ブラウザを閉じて再度お手続きをお願いします。
        </div>
      </section>
      <div>
        <button
          className="button-history-back mgt12"
          onClick={onClickPreviousPage}
        >
          前の画面に戻る
        </button>
      </div>
    </article>
  );
};

export default Error;
