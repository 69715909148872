import { CodeVO } from "../../model/CommonModel";
import { EMPTY_MESSAGE } from "../../../common/enum/EnumForAipom";
/**
 * 共通ドロップダウンコンポーネント
 * @param props
 * @returns
 */
const BaseDropdown = (props: {
  codeList: Array<CodeVO> | undefined;
  placeholder?: any;
  value: any;
  isStartValidation: boolean;
  disabled?: boolean;
  className?: string;
  onChange: (e: any) => void;
}) => {
  const {
    value,
    placeholder,
    codeList,
    isStartValidation,
    disabled,
    className,
    onChange,
  } = props;
  return (
    <div>
      <select
        className={className ?? "base-drop-down"}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {placeholder && <option disabled={value}>{placeholder}</option>}
        {codeList &&
          codeList.map((code, index) => {
            return (
              <option value={code.codeValue} key={index}>
                {code.codeName}
              </option>
            );
          })}
      </select>
      <div className="drop-down-arrow"></div>
      {isStartValidation && !value && (
        <div className="base-input-area">
          <div>{EMPTY_MESSAGE}</div>
        </div>
      )}
    </div>
  );
};

export default BaseDropdown;
