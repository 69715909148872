import {
  ValidationVO,
  validationType,
} from "../../component/model/CommonModel";

// 正規式
const hiraRegexp = /^[一-龥ぁ-んァ-ンa-zA-Zー　々]*$/;
const kanaRegexp = /^[ァ-ンー　]*$/;
const emailRegexp =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
const numberRegexp = /[0-9]/;
const engNumRegexp = /^[a-zA-Z0-9]*$/;
const cardNameRegexp = /^[a-zA-Z0-9 ,./-]*$/;

// エラーメッセージ
enum messageWhenInvalid {
  EMPTY = "",
  MIN_LENGTH_PRE = "最低",
  MIN_LENGTH = "文字設定してください",
  MAX_LENGTH_PRE = "最大",
  MAX_LENGTH = "文字まで設定可能です",
  HIRA = "入力不可の文字が含まれています。",
  KANA = "全角カナで設定してください",
  EMAIL = "不正なメールアドレスです",
  NUMBER = "半角数字のみで設定してください",
  ENGLISH_NUMBER = "半角英数字のみで設定してください",
  CARD_NAME = `半角英数字、" "スペース,(コンマ).(ピリオド)-(ハイフン)/(スラッシュ)のみで設定してください。`,
}

/**
 * バリデーション·ユーティール
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-06
 * @updatedAt : 2021-12-17
 */

const ValidationUtil = {
  /**
   * フォームと必須リストを比較
   *
   * @param target フォームデータ
   * @param requireList 必須リスト
   * @returns
   */
  hasValues: (target: any, requireList: Array<string>) => {
    let result = true;

    Object.entries(target).forEach(([key, value]) => {
      if (requireList.includes(key) && !ValidationUtil.hasValue(value)) {
        result = false;
      }
    });

    return result;
  },

  /**
   * データがあるか確認
   *
   * @param target
   * @returns
   */
  hasValue: (target: any) => {
    let result = true;
    if (!target) {
      result = false;
    }

    if (
      target &&
      typeof target === "object" &&
      Object.values(target).length <= 0
    ) {
      result = false;
    }

    return result;
  },

  /**
   * 最小長さに満足しているかを確認
   *
   * @param target
   * @param minLength
   * @returns
   */
  isValidFromMinLength: (target: any, minLength: number) => {
    if (minLength < 0) {
      throw new Error("Max and Min cannot be negative.");
    }
    return target.length >= minLength;
  },
  /**
   * 最大長さの満足度を確認
   *
   * @param target
   * @param maxLength
   * @returns
   */
  isValidFromMaxLength: (target: any, maxLength: number) => {
    if (maxLength < 0) {
      throw new Error("Max and Min cannot be negative.");
    }

    return target.length <= maxLength;
  },

  /**
   * ひらがな形式の有無を確認
   *
   * @param target
   * @returns
   */
  isValidFromHira: (target: any) => {
    return hiraRegexp.test(target);
  },

  /**
   * カタカナ形式の有無を確認
   *
   * @param target
   * @returns
   */
  isValidFromKana: (target: any) => {
    return kanaRegexp.test(target);
  },

  /**
   * メール形式の有無を確認
   *
   * @param target
   * @returns
   */
  isValidFromEmail: (target: any) => {
    return emailRegexp.test(target);
  },

  /**
   * 半角数字形式の有無を確認
   *
   * @param target
   * @returns
   */
  isValidFromNumber: (target: any) => {
    return numberRegexp.test(target);
  },

  /**
   * 英語形式の有無を確認
   *
   * @param target
   * @returns
   */
  isValidFromEnglishAndNumber: (target: any) => {
    return engNumRegexp.test(target);
  },

  isValidFromCardName: (target: any) => {
    return cardNameRegexp.test(target);
  },

  /**
   * オプション基準でバリデーション
   *
   * @param target
   * @param option
   * @returns エラーメッセージの返還
   */
  validationByOption: (target: any, option: ValidationVO) => {
    let errorMessage: string = messageWhenInvalid.EMPTY;
    const { minLength, maxLength, format } = option;

    const targetString = target + "";

    if (
      minLength &&
      !ValidationUtil.isValidFromMinLength(targetString, minLength)
    ) {
      errorMessage =
        messageWhenInvalid.MIN_LENGTH_PRE +
        minLength +
        messageWhenInvalid.MIN_LENGTH;
    }

    if (
      maxLength &&
      !ValidationUtil.isValidFromMaxLength(targetString, maxLength)
    ) {
      errorMessage =
        messageWhenInvalid.MAX_LENGTH_PRE +
        maxLength +
        messageWhenInvalid.MAX_LENGTH;
    }

    switch (format as validationType) {
      case "hira":
        if (!ValidationUtil.isValidFromHira(target)) {
          errorMessage = messageWhenInvalid.HIRA;
        }
        break;
      case "kana":
        if (!ValidationUtil.isValidFromKana(target)) {
          errorMessage = messageWhenInvalid.KANA;
        }
        break;
      case "email":
        if (!ValidationUtil.isValidFromEmail(target)) {
          errorMessage = messageWhenInvalid.EMAIL;
        }
        break;
      case "number":
        if (!ValidationUtil.isValidFromNumber(target)) {
          errorMessage = messageWhenInvalid.NUMBER;
        }
        break;
      case "engNum":
        if (!ValidationUtil.isValidFromEnglishAndNumber(target)) {
          errorMessage = messageWhenInvalid.ENGLISH_NUMBER;
        }
        break;
      case "cardName":
        if (!ValidationUtil.isValidFromCardName(target)) {
          errorMessage = messageWhenInvalid.CARD_NAME;
        }
        break;
      default:
        break;
    }

    return errorMessage;
  },
};
export default ValidationUtil;
