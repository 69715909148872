import { useState, useEffect, useRef } from "react";
import {
  useContextState,
  useContextDispatch,
} from "../../common/service/Context";
import { getAgeByBaseDate, getDataForOccupationInput } from "../../common/service/AipomApiService";
import PassedPageManagementUtil from "../../common/util/PassedPageManagementUtil";
import DateUtil from "../../common/util/DateUtil";
import ValidationUtil from "../../common/util/ValidationUtil";
import PageInfo from "../../common/constant/PageInfo";
import { CodeVO } from "../model/CommonModel";
import { JobsVO } from "../model/JobsModel";
import { AgeRequestVO } from "../model/SalesModel";
import { SEX_CODE } from "../../common/constant/CodeForAipom";
import {
  EMPTY_MESSAGE,
  ETC_OF_JOB_DETAIL,
  PENDING_MESSAGE,
  MANUFACTURER_CODE,
} from "../../common/enum/EnumForAipom";
import { Link } from "react-router-dom";
import BaseModal from "../ui/base/BaseModal";
import BaseDropdown from "../ui/base/BaseDropdown";
import BaseInputText from "../ui/base/BaseInputText";
import Navigator from "../common/Navigator";
import InfoMessage from "../ui/InfoMessage";

const REQUIRE_LIST = [
  "jobId",
  "job",
  "yearOfBirth",
  "monthOfBirth",
  "dayOfBirth",
  "sex",
];

const defaultData = {
  formData: {
    jobId: "",
    job: "",
    yearOfBirth: 0,
    monthOfBirth: 0,
    dayOfBirth: 0,
    sex: "",
    otherJob: "",
    category: "",
  },
};

/**
 * 職業入力
 *
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-06
 * @updatedAt : 2021-12-17
 */
const OccupationInput = (props: any) => {
  const [formData, setFormData] = useState<any>(defaultData.formData);
  const [canApplication, setCanApplication] = useState<boolean>(false);
  const [jobNames, setJobNames] = useState<Array<CodeVO>>();
  const [jobDetails, setJobDetails] = useState<Array<CodeVO>>();
  const [isStartValidation, setIsStartValidation] = useState<boolean>(false);
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);
  const [isInvalidBirthDay, setIsInvalidBirthDay] = useState<boolean>(false);
  const [age, setAge] = useState<number>(-1);
  const isModifyMode = useRef<boolean>(false);
  const isPending = useRef<boolean>(false);
  const disabledJobDetail = useRef<boolean>(false);
  const canApplicationAge = useRef<boolean>(true);
  let { securityStartAt, birthFrom, birthTo } = props.data;
  const saleId = window.location.search.replace("?saleId=", "");
  const jobs: Array<JobsVO> = props.data.jobs;

  const state = useContextState();
  const dispatch = useContextDispatch();

  useEffect(() => {
    if (props.currentPage !== PageInfo.path.occupation.input) {
      window.scrollTo(0, 0);
      props.setCurrentPage(PageInfo.path.occupation.input);
    }

    PassedPageManagementUtil.isPassedPage(PageInfo.path.occupation.input) &&
      setState();

    if (canApplication) {
      setJobNamesState();
      setJobsDetails();
    }

    // 職業詳細無効化可否セット
    if (formData.jobId && jobs) {
      const selectedJob = jobs.find((job) => {
        return job.id === formData.jobId;
      });
      disabledJobDetail.current = selectedJob?.code !== MANUFACTURER_CODE;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    securityStartAt,
    saleId,
    jobs,
    canApplication,
    formData.sex,
    formData.jobId,
    formData.coverageType,
    canApplicationAge.current,
  ]);

  useEffect(() => {
    getAge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.yearOfBirth, formData.monthOfBirth, formData.dayOfBirth]);

  /**
   * 職業告知NGで画面遷移
   */
  const onClickCancelButton = () => {
    props.history.push(PageInfo.path.occupation.cancel + saleId);
  };

  /**
   * 加入前確認で画面遷移
   * @returns
   */
  const onClickNextButton = () => {
    if (
      !ValidationUtil.hasValues(formData, REQUIRE_LIST) ||
      (formData.job === ETC_OF_JOB_DETAIL &&
        (!ValidationUtil.hasValue(formData.otherJob) ||
          !ValidationUtil.isValidFromMaxLength(formData.otherJob, 20))) ||
      isPending.current
    ) {
      setIsStartValidation(true);
      setIsInvalidData(true);
      return;
    }

    if (!canApplicationAge.current) {
      setIsInvalidBirthDay(true);
      return;
    }
    setContext();
    PassedPageManagementUtil.setPassedPageList(PageInfo.path.occupation.input);
    props.history.push(PageInfo.path.occupation.plan + saleId);
  };

  /**
   * 職務内容（大分類）選択イベント
   * @param e
   */
  const onSelectJobName = (e: any) => {
    const selectedJob = jobs.find((job) => {
      return job.id === e.target.value;
    });

    disabledJobDetail.current = selectedJob?.code !== MANUFACTURER_CODE;

    if (disabledJobDetail.current) {
      // 職務内容が製作業者でない場合
      setFormData({
        ...formData,
        jobId: e.target.value,
        job: selectedJob?.details[0].name ?? "",
        category: selectedJob?.details[0].category ?? "",
      });
    } else {
      setFormData({
        ...formData,
        jobId: e.target.value,
        job: "",
      });
    }
  };

  /**
   * 職務内容が製作業者の場合選択可能
   * @param e
   */
  const onSelectJobDetail = (e: any) => {
    const { value } = e.target;
    const selectedJob = jobs.find((job) => job.code === MANUFACTURER_CODE);
    const selectedDetail = selectedJob?.details.find(
      (detail) => detail.name === value
    );

    setFormData({
      ...formData,
      job: value,
      category: selectedDetail?.category,
    });
  };

  /**
   * 修正時データセッティング
   */
  const setState = () => {
    if (isModifyMode.current) {
      return;
    }
    const stateData = {
      otherJob: state.insureds[0].otherJob,
      job: state.insureds[0].job,
      jobId: state.insureds[0].jobId,
      sex: state.insureds[0].sex,
      yearOfBirth: state.insureds[0].yearOfBirth,
      monthOfBirth: state.insureds[0].monthOfBirth,
      dayOfBirth: state.insureds[0].dayOfBirth,
      category: state.insureds[0].category,
    } as any;

    isModifyMode.current = true;
    setCanApplication(true);
    setFormData(stateData);
  };

  /**
   * データ全域ステートに保存
   */
  const setContext = () => {
    let tempInsured = { ...state.insureds };
    const category = formData.category;
    const jobName = jobs.find((job) => job.id === formData.jobId)?.name;
    const { yearOfBirth, monthOfBirth, dayOfBirth } = formData;

    const dateOfBirth =
      yearOfBirth +
      "-" +
      monthOfBirth.toString().padStart(2, "0") +
      "-" +
      dayOfBirth.toString().padStart(2, "0");

    tempInsured[0].job = formData.job;
    tempInsured[0].jobId = formData.jobId;
    tempInsured[0].dateOfBirth = dateOfBirth;
    tempInsured[0].otherJob =
      formData.job === ETC_OF_JOB_DETAIL ? formData.otherJob : "";
    tempInsured[0].category = category ?? "";
    tempInsured[0].sex = formData.sex;
    tempInsured[0].jobName = jobName ?? "";
    tempInsured[0].age = age ?? 0;
    tempInsured[0].yearOfBirth = yearOfBirth;
    tempInsured[0].monthOfBirth = monthOfBirth;
    tempInsured[0].dayOfBirth = dayOfBirth;

    dispatch({ type: "SET_SALE_ID", saleId: saleId });
    dispatch({ type: "SET_INSURED", insureds: tempInsured });
  };

  /**
   * 職業リストセット
   *
   * @returns 職業リスト
   */
  const setJobNamesState = () => {
    if (!props.data.jobs) {
      return;
    }
    const tempList = [] as Array<CodeVO>;

    jobs.forEach((item) => {
      const code = { codeName: item.name, codeValue: item.id } as CodeVO;

      tempList.push(code);
    });

    setJobNames(tempList);
  };

  /**
   * 職業詳細リストセット
   *
   * @returns 職業詳細リスト
   */
  const setJobsDetails = () => {
    if (!props.data.jobs || !formData.jobId) {
      return;
    }
    const tempList = [] as Array<CodeVO>;

    jobs.forEach((item) => {
      if (item.id === formData.jobId) {
        item.details.forEach((detail) => {
          const code = {
            codeName: detail.name,
            codeValue: detail.name,
          } as CodeVO;

          tempList.push(code);
        });
      }
    });

    setJobDetails(tempList);
  };

  /**
   * 保険開始日時点の年齢返還
   *
   * @returns 保険開始日時点の年齢
   */
  const getAge = async () => {


    if (!securityStartAt || !birthFrom || !birthTo) {
      const occupationInputData = await getDataForOccupationInput(saleId);
      securityStartAt = occupationInputData.securityStartAt;
      birthFrom = occupationInputData.birthFrom;
      birthTo = occupationInputData.birthTo;
    }

    if (
      !formData.yearOfBirth ||
      !formData.monthOfBirth ||
      !formData.dayOfBirth
    ) {
      setAge(-1);
      return;
    }
    const birthDayFrom = new Date(birthFrom);
    const birthDayTo = new Date(birthTo);
    const birthDate = new Date(
      formData.yearOfBirth +
      "-" +
      (formData.monthOfBirth as string).padStart(2, "0") +
      "-" +
      (formData.dayOfBirth as string).padStart(2, "0")
    );

    canApplicationAge.current =
      (birthFrom && birthDayFrom <= birthDate) ||
      (birthTo && birthDayTo > birthDate);

    const params: AgeRequestVO = {
      baseDate: DateUtil.convertDateFormat(securityStartAt, "yyyy-MM-DD"),
      dateOfBirth: DateUtil.convertDateFormat(birthDate, "yyyy-MM-DD"),
    };

    isPending.current = true;

    const age = await getAgeByBaseDate(params);

    isPending.current = false;

    setAge(age);
  };

  return (
    <article>
      <section className="main-container mgt24 mgb40">
        <Navigator currentPage={PageInfo.path.occupation.input} />

        <h2 className="font-color-blue font-weight-bold">
          2.加入者情報の入力（告知事項等）
        </h2>
        <div className="mgb12">
          <p className="font-weight-bold warning">
            <span>（注）</span>
            <span>職業・職務内容と年齢は告知事項です。</span>
          </p>
          <h4 className="content-board-title font-weight-bold font-color-darkbrown">
            加入される方のご職業は、次の「ご加入いただけないご職業」に該当しますか？
          </h4>

          <div className="mgt12 mgb12">
            <div className="width100">
              <button className="radio-button" onClick={onClickCancelButton}>
                <div className="text-align-left">
                  <div className="radio-button-check">
                    <div></div>
                  </div>
                  <span className="radio-button-text font-color-darkbrown">
                    はい
                  </span>
                </div>
              </button>
            </div>

            <div className="width100">
              <button
                className={
                  !!canApplication && canApplication
                    ? "radio-button-active"
                    : "radio-button"
                }
                onClick={() => {
                  setCanApplication(true);
                }}
              >
                <div className="text-align-left">
                  {!!canApplication && canApplication ? (
                    <div className="radio-button-check-active">
                      <div className="radio-button-check-item"></div>
                    </div>
                  ) : (
                    <div className="radio-button-check">
                      <div></div>
                    </div>
                  )}

                  <span
                    className={
                      !!canApplication && canApplication
                        ? "radio-button-text font-color-orange"
                        : "radio-button-text font-color-darkbrown"
                    }
                  >
                    いいえ
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="occupation-list">
          <label className="occupation-list-header font-weight-bold mgb12">
            ご加入いただけないご職業
          </label>
          <div className="occupation-list-item-margin">
            炭坑・鉱山技術者、操縦実習を行う航空学校教員、職業スポーツ家、空手術師範、乗馬・競馬等取扱者（調教師を除く）、猛獣取扱者、運転代行運転者、自家用または使用事業航空機に搭乗する航空カメラマン、使用事業航空機の航空機関係従事者、テストパイロット、バイク配達員、船内・沿岸・港湾における運搬作業者、火薬類・強酸・劇毒物などの危険物の製造作業者、潜水作業者、送電線架線工、自衛官、警察官、海上保安官、消防員、麻薬取締官、登山ガイド、産業廃棄物処理作業者、3階以上の高所または壁面などの清掃員、バーテンダー・接客社交従事者、風俗関連従事者
          </div>
        </div>
      </section>

      {canApplication && (
        <>
          <section className="content-board mgb24 background-bage no-mgt">
            <h4 className="content-board-title font-weight-bold font-color-darkbrown mgb24 no-mgt mgl8">
              加入される方の、職務内容（大分類）を選択し、次に職務内容詳細を選択してください
            </h4>
            <div className="content-board-content border-line-blue">
              <div>
                <label className="font-weight-bold font-size-18">
                  職務内容（大分類）
                </label>
                <label className="required-label">必須</label>

                <div className="mgb12 mgt12 max-width340">
                  <BaseDropdown
                    placeholder={!jobs ? PENDING_MESSAGE : "選択"}
                    codeList={jobNames}
                    value={formData.jobId}
                    onChange={onSelectJobName}
                    isStartValidation={isStartValidation}
                  />
                </div>
              </div>
              <div>
                <label className="font-weight-bold font-size-18">
                  職務内容詳細
                </label>
                <label className="required-label">必須</label>
                <div className="mgb12 mgt12 max-width340">
                  <BaseDropdown
                    placeholder="選択"
                    codeList={jobDetails}
                    disabled={disabledJobDetail.current}
                    value={formData.job}
                    onChange={onSelectJobDetail}
                    isStartValidation={isStartValidation}
                  />
                  {formData.job === ETC_OF_JOB_DETAIL && (
                    <div className="input-text-two-cols">
                      <span>具体的なお仕事</span>
                      <BaseInputText
                        value={formData.otherJob}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            otherJob: e.target.value,
                          });
                        }}
                        validationOptions={{
                          maxLength: 20,
                        }}
                        isStartValidation={isStartValidation}
                      />
                    </div>
                  )}
                </div>
                <p className="warning">
                  <span>
                    職務内容（大分類）と同じ内容が自動セットされている場合は、選択不要です。
                  </span>
                </p>
              </div>

              <div>
                <label className="font-weight-bold font-size-18">
                  職種級別
                </label>
                <p className="font-color-darkbrown font-size-18 font-weight-normal">
                  {formData.category ? formData.category : "(未設定)"}
                </p>
              </div>
            </div>
            {formData.job === ETC_OF_JOB_DETAIL && (
              <section className="main-container mgt24 mgb24 pc-no-padding">
                <div className="content-board-content board-info-text radius20">
                  <InfoMessage>
                    <div>
                      ご入力いただいた職務内容によっては、お引き受けできない場合もありますので、あらかじめご了承ください。
                    </div>
                  </InfoMessage>
                </div>
              </section>
            )}
          </section>

          <section className="mgt24 mgb24">
            <h4 className="content-board-title font-weight-bold font-color-darkbrown  mgl8">
              加入される方の生年月日と性別を入力してください
            </h4>
            <div className="main-container">
              <p className="warning">
                <span>（注）</span>
                <span>
                  保険期間開始日時点での年齢が満81歳以上の場合はご加入いただけません。
                </span>
              </p>

              <div className="container-common-border border-line-blue">
                <div className="displayFlexWide">
                  <div className="birthDayArea">
                    <label className="font-weight-bold">生年月日</label>
                    <label className="required-label">必須</label>
                    <div className="displayFlexWide">
                      <div className="mgb12 mgt12 drop-down-birth">
                        <BaseDropdown
                          placeholder="年"
                          codeList={DateUtil.yearCodeList((new Date(birthFrom)).getFullYear(), (birthTo ? new Date(birthTo) : new Date()).getFullYear(), "DESC")}
                          value={formData.yearOfBirth}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              yearOfBirth: e.target.value,
                            });
                          }}
                          isStartValidation={false}
                        />
                      </div>
                      <div className="mgb12 mgt12 drop-down-birth">
                        <BaseDropdown
                          placeholder="月"
                          codeList={DateUtil.monthCodeList()}
                          value={formData.monthOfBirth}
                          onChange={(e) => {
                            const lastDay = new Date(
                              formData.yearOfBirth,
                              e.target.value,
                              0
                            ).getDate();

                            const dayOfBirth =
                              formData.dayOfBirth <= lastDay
                                ? formData.dayOfBirth
                                : 0;

                            setFormData({
                              ...formData,
                              monthOfBirth: e.target.value,
                              dayOfBirth: dayOfBirth,
                            });
                          }}
                          isStartValidation={false}
                        />
                      </div>
                      <div className="mgb12 mgt12 drop-down-birth">
                        <BaseDropdown
                          placeholder="日"
                          codeList={DateUtil.dayCodeList(formData.yearOfBirth,
                            formData.monthOfBirth)}
                          value={formData.dayOfBirth}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              dayOfBirth: e.target.value,
                            });
                          }}
                          isStartValidation={false}
                        />
                      </div>
                    </div>
                    {isStartValidation &&
                      (!formData.yearOfBirth ||
                        !formData.monthOfBirth ||
                        !formData.dayOfBirth) && (
                        <div className="base-input-area mgb12">
                          <div>{EMPTY_MESSAGE}</div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="ageArea">
                  <div className="displayFlex">
                    <label className="font-weight-bold">
                      年齢（保険期間開始日時点）
                    </label>
                  </div>
                  <p className="font-color-darkbrown font-size-18 font-weight-normal">
                    {age !== -1 ? age + "歳" : "(未設定)"}
                  </p>
                </div>

                <div>
                  <label className="font-weight-bold">性別</label>
                  <label className="required-label">必須</label>

                  <div className="two-cols">
                    {SEX_CODE.map((code, index) => {
                      return (
                        <label
                          className={
                            formData.sex === code.codeValue
                              ? "base-radio-label-active"
                              : "base-radio-label"
                          }
                          key={"sex_" + index}
                        >
                          {formData.sex === code.codeValue ? (
                            <div className="radio-button-check-active">
                              <div className="radio-button-check-item"></div>
                            </div>
                          ) : (
                            <div className="radio-button-check">
                              <div></div>
                            </div>
                          )}
                          <input
                            className="visibility-collapse"
                            type="radio"
                            name="sex"
                            defaultChecked={formData.sex === code.codeValue}
                            value={code.codeValue}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                sex: e.target.value,
                              });
                            }}
                          />
                          <span
                            className={
                              formData.sex === code.codeValue
                                ? "radio-button-text font-color-orange"
                                : "radio-button-text font-color-darkbrown"
                            }
                          >
                            {code.codeName}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                  {isStartValidation && !formData.sex && (
                    <div className="base-input-area">
                      <div>{EMPTY_MESSAGE}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <div className="board-button-two">
            <div>
              <button
                className="button-confirm-orange"
                onClick={onClickNextButton}
              >
                <div>
                  <span></span>
                  <span>次へ</span>
                  <span className="button-arrow"></span>
                </div>
              </button>
            </div>
            <div className="board-link">
              <Link
                to={PageInfo.path.application.preConfirm + saleId}
              >
                戻る
              </Link>
            </div>
          </div>
        </>
      )}

      <section>
        <BaseModal isOpen={isInvalidData} setIsOpen={setIsInvalidData}>
          <h4>入力項目を確認してください。</h4>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsInvalidData(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseModal isOpen={isInvalidBirthDay} setIsOpen={setIsInvalidBirthDay}>
          <h4>すみません、加入できない年齢です。</h4>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsInvalidBirthDay(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
      </section>
    </article>
  );
};

export default OccupationInput;
