import { useEffect, useRef, useState } from "react";
import {
  useContextState,
  useContextDispatch,
} from "../../common/service/Context";
import PassedPageManagementUtil from "../../common/util/PassedPageManagementUtil";
import ValidationUtil from "../../common/util/ValidationUtil";
import { ValidationVO } from "../model/CommonModel";
import { getAddressByZipcode } from "../../common/service/AipomApiService";
import { Link } from "react-router-dom";
import PageInfo from "../../common/constant/PageInfo";
import BaseInputText from "../ui/base/BaseInputText";
import BaseModal from "../ui/base/BaseModal";
import Navigator from "../common/Navigator";

const REQUIRE_LIST = [
  "contractorFirstName",
  "contractorLastName",
  "contractorKanaFirstName",
  "contractorKanaLastName",
  "zipcode",
  "prefecture",
  "municipalities",
  "streetNumber",
  "phone",
  "employeeNumber",
  "email",
];

const VALIDATION_OPTIONS = {
  contractorLastName: { maxLength: 10, format: "hira" },
  contractorFirstName: { maxLength: 10, format: "hira" },
  contractorKanaLastName: { maxLength: 10, format: "kana" },
  contractorKanaFirstName: { maxLength: 10, format: "kana" },
  zipcode: { minLength: 7, maxLength: 7, format: "number" },
  prefecture: { maxLength: 47 },
  municipalities: { minLength: 1 },
  streetNumber: { minLength: 1 },
  phone: {
    maxLength: 11,
    minLength: 10,
    format: "number",
  },
  employeeNumber: { maxLength: 10, format: "engNum" },
  officeCode: { maxLength: 10, format: "engNum" },
  email: { format: "email" },
  insuredLastName: { maxLength: 10 },
  insuredFirstName: { maxLength: 10 },
  insuredKanaLastName: { maxLength: 10, format: "kana" },
  insuredKanaFirstName: { maxLength: 10, format: "kana" },
} as any;

const defaultData = {
  formData: {
    contractorFirstName: "",
    contractorLastName: "",
    contractorKanaFirstName: "",
    contractorKanaLastName: "",
    zipcode: "",
    prefecture: "",
    municipalities: "",
    streetNumber: "",
    buildingName: "",
    phone: "",
    employeeNumber: "",
    officeCode: "",
    email: "",
    checkEmail: "",
    insuredFirstName: "",
    insuredLastName: "",
    insuredKanaFirstName: "",
    insuredKanaLastName: "",
  },
};

/**
 * 加入情報入力
 *
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-09
 * @updatedAt : 2021-12-17
 */
const ApplicationInput = (props: any) => {
  const [formData, setFormData] = useState<any>(defaultData.formData);
  const [isStartValidation, setIsStartValidation] = useState<boolean>(false);
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const receivedAddressList = useRef<Array<any>>([]);
  const isPending = useRef<boolean>(false);
  const isModifyMode = useRef<boolean>(false);
  const [isShowingMailCheckMessage, setIsShowingMailCheckMessage] =
    useState<boolean>(false);
  const { employeeLabel, requireOffice } = props.data;

  const state = useContextState();
  const dispatch = useContextDispatch();

  useEffect(() => {
    if (props.currentPage !== PageInfo.path.application.input) {
      window.scrollTo(0, 0);
      props.setCurrentPage(PageInfo.path.application.input);
    }

    PassedPageManagementUtil.isPassedPage(PageInfo.path.application.input) &&
      setState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requireOffice, employeeLabel, formData]);

  /**
   * 告知で画面遷移
   *
   * @returns
   */
  const onClickNextButton = () => {
    if (
      !ValidationUtil.hasValues(formData, REQUIRE_LIST) ||
      formData.email !== formData.checkEmail ||
      !isValidData()
    ) {
      setIsInvalidData(true);
      setIsStartValidation(true);
      return;
    }

    setContext();
    PassedPageManagementUtil.setPassedPageList(PageInfo.path.application.input);
    props.history.push(
      PageInfo.path.application.notify + state.saleId
    );
  };

  /**
   * 住所取得
   *
   * @returns
   */
  const onBlurGetAddress = async (e: any) => {
    const filteredZipcode = filterHyphen(formData.zipcode);

    e.target.value = filteredZipcode;

    setFormData({ ...formData, zipcode: filteredZipcode });
    if (
      ValidationUtil.validationByOption(
        filteredZipcode,
        VALIDATION_OPTIONS.zipcode
      ) ||
      isPending.current
    ) {
      return;
    }

    isPending.current = true;
    const { result } = (await getAddressByZipcode(filteredZipcode)) as any;
    const { message, status, results } = result;
    isPending.current = false;

    if (status !== 200 || message || !results) {
      return;
    }

    const prefectureInput = document.getElementById("prefecture");
    const municipalitiesInput = document.getElementById("municipalities");

    if (results.length === 1) {
      setFormData({
        ...formData,
        prefecture: results[0].address1,
        municipalities: results[0].address2 + results[0].address3,
      });

      prefectureInput?.focus();
      prefectureInput?.blur();
      municipalitiesInput?.focus();
      municipalitiesInput?.blur();
    } else {
      receivedAddressList.current = results;
      setIsAddressModalOpen(true);
    }
  };

  /**
   * 電話番号ハイフンオフ
   *
   * @param e
   */
  const onBlurFilterPhone = (e: any) => {
    const filteredPhone = filterHyphen(formData.phone);
    e.target.value = filteredPhone;
    setFormData({ ...formData, phone: filteredPhone });
  };

  /**
   * 選択されたアドレスにセッティング
   */
  const onClickSelectAddressButton = () => {
    const address = receivedAddressList.current[selectedAddressIndex ?? 0];
    const prefectureInput = document.getElementById("prefecture");
    const municipalitiesInput = document.getElementById("municipalities");

    setFormData({
      ...formData,
      prefecture: address.address1,
      municipalities: address.address2 + address.address3,
    });
    setIsAddressModalOpen(false);

    prefectureInput?.focus();
    prefectureInput?.blur();
    municipalitiesInput?.focus();
    municipalitiesInput?.blur();
  };

  /**
   * 有効性検査
   *
   * @returns 有効性検査結果
   */
  const isValidData = () => {
    let result = true;

    Object.entries(formData).forEach(([key, value]) => {
      const validationOption = VALIDATION_OPTIONS[key] as ValidationVO;

      if (
        validationOption &&
        ValidationUtil.validationByOption(value, validationOption)
      ) {
        result = false;
      }
    });

    const address =
      formData.prefecture +
      formData.municipalities +
      formData.streetNumber +
      formData.buildingName;

    if (!ValidationUtil.isValidFromMaxLength(address, 50)) {
      result = false;
    }

    return result;
  };

  /**
   * 修正時データセッティング
   */
  const setState = () => {
    if (isModifyMode.current) {
      return;
    }

    let stateData = {
      contractorFirstName: state.contractor.firstName,
      contractorLastName: state.contractor.lastName,
      contractorKanaFirstName: state.contractor.kanaFirstName,
      contractorKanaLastName: state.contractor.kanaLastName,
      zipcode: state.contractor.zipcode,
      phone: state.contractor.phone,
      employeeNumber: state.contractor.employeeNumber,
      officeCode: state.contractor.officeCode,
      email: state.contractor.email,
      checkEmail: state.contractor.email,
      prefecture: state.contractor.prefecture,
      municipalities: state.contractor.municipalities,
      streetNumber: state.contractor.streetNumber,
      buildingName: state.contractor.buildingName,
    };
    isModifyMode.current = true;
    setFormData(stateData);
  };

  /**
   * データ全域ステートに保存
   */
  const setContext = () => {
    let tempContractor = { ...state.contractor };

    tempContractor.firstName = formData.contractorFirstName;
    tempContractor.lastName = formData.contractorLastName;
    tempContractor.kanaFirstName = formData.contractorKanaFirstName;
    tempContractor.kanaLastName = formData.contractorKanaLastName;
    tempContractor.zipcode = formData.zipcode;
    tempContractor.address =
      formData.prefecture +
      formData.municipalities +
      formData.streetNumber +
      formData.buildingName;
    tempContractor.phone = formData.phone;
    tempContractor.employeeNumber = formData.employeeNumber;
    tempContractor.officeCode = formData.officeCode;
    tempContractor.email = formData.email;
    tempContractor.prefecture = formData.prefecture;
    tempContractor.municipalities = formData.municipalities;
    tempContractor.streetNumber = formData.streetNumber;
    tempContractor.buildingName = formData.buildingName;

    let tempInsured = { ...state.insureds };
    tempInsured[0].firstName = formData.contractorFirstName;
    tempInsured[0].lastName = formData.contractorLastName;
    tempInsured[0].kanaFirstName = formData.contractorKanaFirstName;
    tempInsured[0].kanaLastName = formData.contractorKanaLastName;

    dispatch({ type: "SET_CONTRACTOR", contractor: tempContractor });
    dispatch({ type: "SET_INSURED", insureds: tempInsured });
  };

  /**
   * ハイフン除去
   *
   * @param text 文言
   * @returns ハイフン除去された文言
   */
  const filterHyphen = (text: string) => {
    const hyphenList = ["―", "ー", "‐", "₋", "―", "―", "⁻", "₋", "-"];
    let result = text;

    hyphenList.forEach((hyphen) => {
      result = result.replaceAll(hyphen, "");
    });

    return result;
  };

  return (
    <article>
      <section className="main-container mgt24 mgb24">
        <Navigator currentPage={PageInfo.path.application.input} />
        <h2 className="font-color-blue font-weight-bold">
          4.加入者情報の入力（お名前等）
        </h2>

        <div className="mgb12">
          <h4 className="content-board-title font-weight-bold font-color-darkbrown">
            加入される方のお名前、住所などを入力してください
          </h4>
        </div>
        <div className="container-common-border border-line-blue">
          <label className="font-weight-bold font-size-18">
            加入者名（漢字）
          </label>
          <label className="required-label">必須</label>
          <div className="input-items-two-cols">
            <div className="mgt8">
              <div className="mgb8">姓</div>
              <BaseInputText
                value={formData.contractorLastName}
                placeholder="例：永合"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contractorLastName: e.target.value,
                  });
                }}
                validationOptions={VALIDATION_OPTIONS.contractorLastName}
                isStartValidation={isStartValidation}
                className="base-input-text two-col-item-width"
              />
            </div>

            <div className="mgt8">
              <div className="mgb8">名</div>
              <BaseInputText
                value={formData.contractorFirstName}
                placeholder="例：次郎"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contractorFirstName: e.target.value,
                  });
                }}
                validationOptions={VALIDATION_OPTIONS.contractorFirstName}
                isStartValidation={isStartValidation}
                className="base-input-text two-col-item-width"
              />
            </div>
          </div>
          <label className="font-weight-bold font-size-18">
            加入者名（カナ）
          </label>
          <label className="required-label">必須</label>
          <div className="input-items-two-cols">
            <div className="mgt8">
              <div className="mgb8">セイ</div>
              <BaseInputText
                value={formData.contractorKanaLastName}
                placeholder="例：エイアイ"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contractorKanaLastName: e.target.value,
                  });
                }}
                validationOptions={VALIDATION_OPTIONS.contractorKanaLastName}
                isStartValidation={isStartValidation}
                className="base-input-text two-col-item-width"
              />
            </div>

            <div className="mgt8">
              <div className="mgb8">メイ</div>
              <BaseInputText
                value={formData.contractorKanaFirstName}
                placeholder="例：ジロウ"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contractorKanaFirstName: e.target.value,
                  });
                }}
                validationOptions={VALIDATION_OPTIONS.contractorKanaFirstName}
                isStartValidation={isStartValidation}
                className="base-input-text two-col-item-width"
              />
            </div>
          </div>
          <label className="font-weight-bold font-size-18">加入者住所</label>
          <label className="required-label">必須</label>
          <div className="input-items-two-cols">
            <div className="mgt8">
              <div className="mgb8 height2_5em">
                郵便番号
                <br />
                （ハイフンなし）
              </div>
              <BaseInputText
                type="number"
                placeholder="例：1030007"
                value={formData.zipcode}
                onBlur={onBlurGetAddress}
                onChange={(e) => {
                  !isPending.current &&
                    setFormData({ ...formData, zipcode: e.target.value });
                }}
                validationOptions={VALIDATION_OPTIONS.zipcode}
                isStartValidation={isStartValidation}
                className="base-input-text two-col-item-width"
              />
            </div>

            <div className="mgt8">
              <div className="mgb8 height2_5em line-height2_5em">都道府県</div>
              <BaseInputText
                id={"prefecture"}
                value={formData.prefecture}
                placeholder="例：東京都"
                onChange={(e) => {
                  setFormData({ ...formData, prefecture: e.target.value });
                }}
                validationOptions={VALIDATION_OPTIONS.prefecture}
                isStartValidation={isStartValidation}
                className="base-input-text two-col-item-width"
              />
            </div>
          </div>
          <p className="warning">
            <span>
              郵便番号を入力すると、都道府県と市区町村が自動入力されます。
            </span>
          </p>
          <div className="input-text-two-cols">
            <span>市区町村</span>
            <BaseInputText
              id={"municipalities"}
              value={formData.municipalities}
              placeholder="例：中央区日本橋浜町"
              onChange={(e) => {
                setFormData({ ...formData, municipalities: e.target.value });
              }}
              validationOptions={VALIDATION_OPTIONS.municipalities}
              isStartValidation={isStartValidation}
              className="base-input-text-long"
            />
          </div>
          <div className="input-text-two-cols">
            <span>丁目・番地</span>
            <BaseInputText
              value={formData.streetNumber}
              placeholder="例：2-2-2"
              onChange={(e) => {
                setFormData({ ...formData, streetNumber: e.target.value });
              }}
              validationOptions={VALIDATION_OPTIONS.streetNumber}
              isStartValidation={isStartValidation}
              className="base-input-text-long"
            />
          </div>
          <div className="input-text-two-cols">
            <span>建物名</span>
            <BaseInputText
              value={formData.buildingName}
              placeholder="例：中央マンション222"
              onChange={(e) => {
                setFormData({ ...formData, buildingName: e.target.value });
              }}
              isStartValidation={false}
              className="base-input-text-long"
            />
          </div>
          {isStartValidation &&
            !ValidationUtil.isValidFromMaxLength(
              formData.prefecture +
              formData.municipalities +
              formData.streetNumber +
              formData.buildingName,
              50
            ) && (
              <div className="base-input-area">
                <div>最大50文字まで設定可能です</div>
              </div>
            )}
          <div className="mgt12 mgb12">
            <label className="font-weight-bold font-size-18">
              自宅または携帯電話番号（ハイフンなし）
            </label>
            <label className="required-label">必須</label>
            <div className="input-text-width-95 mgt8 width240px">
              <BaseInputText
                type="number"
                placeholder="例：0311111111"
                value={formData.phone}
                onBlur={onBlurFilterPhone}
                onChange={(e) => {
                  setFormData({ ...formData, phone: e.target.value });
                }}
                validationOptions={VALIDATION_OPTIONS.phone}
                isStartValidation={isStartValidation}
                className="base-input-text-long"
              />
            </div>
          </div>
          <div className="mgt12 mgb12">
            <label className="font-weight-bold font-size-18">
              {employeeLabel ?? "社員番号"}
            </label>
            <label className="required-label">必須</label>
            <div className="input-text-width-95 mgt8 width240px">
              <BaseInputText
                value={formData.employeeNumber}
                placeholder="例：123456789"
                onChange={(e) => {
                  setFormData({ ...formData, employeeNumber: e.target.value });
                }}
                validationOptions={VALIDATION_OPTIONS.employeeNumber}
                isStartValidation={isStartValidation}
                className="base-input-text-long"
              />
            </div>
          </div>
          {requireOffice && (
            <div className="mgt12 mgb12">
              <label className="font-weight-bold font-size-18">
                事業所番号
              </label>

              <div className="input-text-width-95 mgt8 width240px">
                <BaseInputText
                  value={formData.officeCode}
                  placeholder="例：12345"
                  onChange={(e) => {
                    setFormData({ ...formData, officeCode: e.target.value });
                  }}
                  validationOptions={VALIDATION_OPTIONS.officeCode}
                  isStartValidation={false}
                  className="base-input-text-long"
                  disableEmptyValueCheck={true}
                />
              </div>
              <p className="warning">
                <span>不明な場合、 入力不要です。</span>
              </p>
            </div>
          )}
          <div className="mgt12 mgb12">
            <label className="font-weight-bold font-size-18">
              メールアドレス
            </label>
            <label className="required-label">必須</label>
            <div className="input-text-width-95 mgt8 width340px">
              <BaseInputText
                value={formData.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                }}
                validationOptions={VALIDATION_OPTIONS.email}
                isStartValidation={isStartValidation}
                className="base-input-text-long"
              />
            </div>
          </div>
          <div className="mgt12 mgb12">
            <label className="font-weight-bold font-size-18">
              メールアドレス（確認）
            </label>
            <label className="required-label">必須</label>
            <div className="input-text-width-95 mgt8 width340px">
              <BaseInputText
                value={formData.checkEmail}
                placeholder="確認のため、再度入力してください"
                onChange={(e) => {
                  setFormData({ ...formData, checkEmail: e.target.value });
                }}
                validationOptions={VALIDATION_OPTIONS.email}
                isStartValidation={isStartValidation}
                className="base-input-text-long"
                onBlur={(e) => {
                  setIsShowingMailCheckMessage(
                    !!formData.email &&
                    !!e.target.value &&
                    formData.email !== formData.checkEmail
                  );
                }}
              />
            </div>
            {isShowingMailCheckMessage && (
              <div className="base-input-area">
                <div>メールアドレスが異なっています。</div>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="board-button-two">
        <div>
          <button className="button-confirm-orange" onClick={onClickNextButton}>
            <div>
              <span></span>
              <span>次へ</span>
              <span className="button-arrow"></span>
            </div>
          </button>
        </div>
        <div className="board-link">
          <Link to={PageInfo.path.occupation.plan + state.saleId}>
            戻る
          </Link>
        </div>
      </div>
      <section>
        <BaseModal isOpen={isInvalidData} setIsOpen={setIsInvalidData}>
          <h4>入力項目を確認してください。</h4>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsInvalidData(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseModal
          isOpen={isAddressModalOpen}
          setIsOpen={setIsAddressModalOpen}
        >
          <h4>該当する住所を選択してください</h4>
          {receivedAddressList.current.map((address, index) => {
            return (
              <div>
                <label
                  className={
                    selectedAddressIndex === index
                      ? "base-radio-label-active"
                      : "base-radio-label"
                  }
                >
                  {selectedAddressIndex === index ? (
                    <div className="radio-button-check-active">
                      <div className="radio-button-check-item"></div>
                    </div>
                  ) : (
                    <div className="radio-button-check">
                      <div></div>
                    </div>
                  )}
                  <span
                    className={
                      selectedAddressIndex === index
                        ? "radio-button-text font-color-orange"
                        : "radio-button-text font-color-darkbrown"
                    }
                  >
                    {`${address.address1} ${address.address2} ${address.address3}`}
                  </span>

                  <input
                    className="visibility-collapse"
                    type="radio"
                    onClick={() => setSelectedAddressIndex(index)}
                  />
                </label>
              </div>
            );
          })}

          <p className="mgt24 mgb24 font-size-small">
            上記の住所に該当しない場合は、再度郵便番号をご入力ください
          </p>
          <div>
            <button
              className="button-modal button-confirm-orange display-inline-middle"
              onClick={onClickSelectAddressButton}
            >
              上記の住所で入力する
            </button>
          </div>
          <button
            className="button-common-blue mgt36 "
            onClick={() => setIsAddressModalOpen(false)}
          >
            キャンセル
          </button>
          <div></div>
        </BaseModal>
      </section>
    </article>
  );
};

export default ApplicationInput;
