import { useEffect, useState } from "react";
import DateUtil from "../../common/util/DateUtil";
import PageInfo from "../../common/constant/PageInfo";
import PassedPageManagementUtil from "../../common/util/PassedPageManagementUtil";
import { PENDING_MESSAGE } from "../../common/enum/EnumForAipom";
import Navigator from "../common/Navigator";
import BaseModal from "../ui/base/BaseModal";
import InfoMessage from "../ui/InfoMessage";
import { useContextDispatch, useContextState } from "../../common/service/Context";

/**
 * 加入前確認
 *
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-08
 * @updatedAt : 2021-12-17
 */
const PreConfirm = (props: any) => {
  const [canApplication, setCanApplication] = useState<boolean>(false);
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);
  const [isInvalidStartAt, setIsInvalidStartAt] = useState<boolean>(false);
  const saleId = window.location.search.replace("?saleId=", "");

  const state = useContextState();
  const dispatch = useContextDispatch();

  const { securityStartAt, securityEndAt, startAt, endAt, underwriter, customerName, payments } =
    props.data;

  useEffect(() => {
    if (props.currentPage !== PageInfo.path.application.preConfirm) {
      window.scrollTo(0, 0);
      props.setCurrentPage(PageInfo.path.application.preConfirm);
    }

    if (payments) {
      dispatch({ type: "SET_CREDIT_FLAG", hasPaymentsType: Object.keys(payments).length > 0 });
    };

    PassedPageManagementUtil.isPassedPage(
      PageInfo.path.application.preConfirm
    ) && setCanApplicationState();
  });

  /**
   * 加入情報入力で画面遷移
   *
   * @returns
   */
  const onClickNextButton = () => {

    if (new Date(startAt) > new Date()) {
      setIsInvalidStartAt(true);
      return;
    }

    if (!canApplication) {
      setIsInvalidData(true);
      return;
    }

    PassedPageManagementUtil.setPassedPageList(
      PageInfo.path.application.preConfirm
    );
    props.history.push(PageInfo.path.occupation.input + saleId);
  };

  /**
   * 職業告知NGで画面遷移
   */
  const onClickCancelButton = () => {
    props.history.push(PageInfo.path.occupation.cancel + saleId);
  };

  /**
   * 初期値セット
   *
   * @returns
   */
  const setCanApplicationState = () => {
    if (canApplication) {
      return;
    }
    setCanApplication(true);
  };

  return (
    <article>
      {new Date(endAt) > new Date() && (
        <>
          <section>
            <h2 className="text-align-center font-weight-normal font-color-navy font-size-16">
              ニューノーマルパスポート <br />
              加入フォームへようこそ！ <br />
              <span className="font-size-14">（手続き完了目安：約5分）</span>
            </h2>
          </section>
          <section className="main-container mgt24 mgb24">
            <Navigator currentPage={PageInfo.path.application.preConfirm} />
            <h2 className="font-color-blue font-weight-bold">
              1.ご加入前確認（制度概要）
            </h2>
            <h4 className="font-weight-normal font-color-darkbrown">
              こちらは下記団体を契約者とした団体保険契約への加入申込みを行う画面です。
            </h4>
            <div className="mgb12">
              <h4 className="content-board-title font-weight-bold font-color-darkbrown">
                お客様は、下記の団体にお勤めの方ですか？
              </h4>
              <div className="mgt12 mgb12">
                <div className="width100">
                  <button
                    className={
                      !!canApplication && canApplication
                        ? "radio-button-active"
                        : "radio-button"
                    }
                    onClick={() => {
                      setCanApplication(true);
                    }}
                  >
                    <div className="text-align-left">
                      {!!canApplication && canApplication ? (
                        <div className="radio-button-check-active">
                          <div className="radio-button-check-item"></div>
                        </div>
                      ) : (
                        <div className="radio-button-check">
                          <div></div>
                        </div>
                      )}

                      <span
                        className={
                          !!canApplication && canApplication
                            ? "radio-button-text font-color-orange"
                            : "radio-button-text font-color-darkbrown"
                        }
                      >
                        はい
                      </span>
                    </div>
                  </button>
                </div>

                <div className="width100">
                  <button className="radio-button" onClick={onClickCancelButton}>
                    <div className="text-align-left">
                      <div className="radio-button-check">
                        <div></div>
                      </div>
                      <span className="radio-button-text font-color-darkbrown">
                        いいえ
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="main-container mgt24 mgb24">
            <div className="container-common-border border-line-blue">
              <label className="font-weight-bold font-color-darkbrown font-size-18">
                団体名
              </label>
              <p className="font-color-navy font-size-18 font-weight-normal">
                {customerName}
              </p>
              <label className="font-weight-bold font-color-darkbrown font-size-18">
                加入いただける方（加入者）
              </label>
              <p className="font-color-navy font-size-18 font-weight-normal">

                {state.hasPaymentsType
                  ? "上記団体の構成員とその家族"
                  : "上記団体から毎月の給与支払日に給与が支給される役員、従業員、職員など"}
              </p>
              <label className="font-weight-bold font-color-darkbrown font-size-18">
                保険の対象となる方（被保険者本人）
              </label>
              <p className="font-color-navy font-size-18 font-weight-normal">
                加入者と同じ
              </p>
              <label className="font-weight-bold font-color-darkbrown font-size-18">
                保険期間
              </label>
              <div className="font-color-navy">
                <p className="font-color-navy font-size-18 font-weight-normal">
                  {securityStartAt
                    ? DateUtil.convertDateFormat(
                      securityStartAt,
                      "YYYY年MM月DD日"
                    ) + " 0時から"
                    : PENDING_MESSAGE}
                </p>
                <p className="font-color-navy font-size-18 font-weight-normal">
                  {securityEndAt
                    ? DateUtil.convertDateFormat(securityEndAt, "YYYY年MM月DD日") +
                    " 16時まで"
                    : PENDING_MESSAGE}
                </p>
              </div>
              <p className="warning">
                <span>（注）</span>
                <span>
                  ご案内チラシ記載の申込締切を過ぎて申込みをされる場合、保険期間開始日が異なります。
                </span>
              </p>

              <label className="font-weight-bold font-color-darkbrown font-size-18">
                保険料の払込み
              </label>
              <p className="font-color-navy font-size-18 font-weight-normal">

                {state.hasPaymentsType
                  ? "加入申込み時に入力いただいたご加入者本人名義のクレジットカードより、保険料を決済します。（一時払）"
                  : "保険期間開始後に、給与より保険料を引き去ります。（12回分割　月払）"}
              </p>

              <label className="font-weight-bold font-color-darkbrown font-size-18">
                引受保険会社
              </label>
              <p className="font-color-navy font-size-18 font-weight-normal">
                {underwriter}
              </p>
            </div>
            <div className="displayFlex max-width640 mgt12 mgb12">
              <InfoMessage>
                <div>
                  申込締切時点で被保険者数が20名に達しなかった場合、この団体保険契約は成立せず、加入申込みも同様に不成立となりますので、あらかじめご了承ください。
                </div>
              </InfoMessage>

            </div>
          </section>
          <div className="board-button-two">
            <div>
              <button className="button-confirm-orange" onClick={onClickNextButton}>
                <div>
                  <span></span>
                  <span>次へ</span>
                  <span className="button-arrow"></span>
                </div>
              </button>
            </div>
          </div>
        </>
      )}
      {new Date(endAt) <= new Date() &&
        <div className="mgt24 mgl20 mgr20">
          <div className="displayFlex mgt12 mgb12">
            <InfoMessage>
              <div>
                申込締切日を過ぎています。
                申込締切を過ぎてお申し込みをご希望の場合、
                ご案内チラシなどに記載のお問合せ先までご連絡をお願いいたします。
              </div>
            </InfoMessage>
          </div>
        </div>
      }
      <section>
        <BaseModal isOpen={isInvalidData} setIsOpen={setIsInvalidData}>
          <h4>入力項目を確認してください。</h4>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsInvalidData(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
      </section>
      <section>
        <BaseModal isOpen={isInvalidStartAt} setIsOpen={setIsInvalidStartAt}>
          <h4>募集開始前です。</h4>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsInvalidStartAt(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
      </section>
    </article>
  );
};

export default PreConfirm;

