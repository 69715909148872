/**
 * ParsedUtilで変更形式を設定
 */
export enum ParseNameEnum {
  JSON,
  NUMBER,
  BOOLEAN,
  DATE,
}

/**
 * リストの表示順番を設定
 */
export enum SortEnum {
  NOT,
  ASC,
  DESC,
}
