import { createContext } from "react";

interface tokenVo {
  token: string | string[];
  setToken: Function;
}

const token: tokenVo = {
  token: "",
  setToken: () => {},
};

createContext(token);

export const getTokenContext = () => {
  return token;
};
