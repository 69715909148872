import axios from "axios";

// server baseUrl
let baseUrl = process.env.REACT_APP_BASE_URL;

let headers = {} as any;
/**
 * api service
 */
const ApiService = {
  /**
   * @param options ApiOptions
   * @param params パラメーター
   * @returns api response
   */
  request: async (options: any, params?: any) => {
    let axiosBody = { ...options };

    if (options.isOpenApi) {
      headers = {
        "content-type": "text/plain;charset=utf-8",
      } as any;
    } else {
      headers = { "x-api-key": process.env.REACT_APP_X_API_KEY } as any;
    }

    if (params) {
      switch (options.method) {
        case "GET":
          axiosBody["params"] = params;
          break;
        default:
          axiosBody["data"] = params;
      }
    }

    // queryに配列の［］を削除
    const transformRequestOptions = (params: any) => {
      let options = "";
      Object.entries<any>(params).forEach(([key, value]) => {
        if (typeof value !== "object" && value) {
          options += `${key}=${value}&`;
        } else if (Array.isArray(value)) {
          value.forEach((el) => {
            options += `${key}=${el}&`;
          });
        }
      });
      return options ? options.slice(0, -1) : options;
    };

    const axiosConfig = axios.create({
      baseURL: baseUrl,
      headers: headers,
      paramsSerializer: (params) => {
        return transformRequestOptions(params);
      },
    });

    return await axiosConfig({ ...axiosBody })
      .then((response) => {
        return { data: response.data, error: undefined };
      })
      .catch((error) => {
        return { data: undefined, error: error };
      });
  },
};

export default ApiService;
