import { useEffect } from "react";

/**
 * サイトヘッダ
 *
 * @param props
 * @returns
 */
const Header = (props: any) => {
  const { customerName, productName, securityStartAt } = props.data;

  useEffect(() => {}, [props.data]);

  /**
   * 保険開始年の返還
   *
   * @returns 保険開始年
   */

  const getYearOfSecurityStartAt = () => {
    if (!securityStartAt) {
      return;
    }
    const date = new Date(securityStartAt);
    return date.getFullYear();
  };
  return (
    <>
      <header className="main-container header-top-border header-bottom-border">
        <div className="header-border"></div>
        <h2 className="text-align-center font-weight-normal font-size-16 font-color-darkbrown">
          {customerName}
        </h2>
        <h2 className="text-align-center font-weight-normal font-size-16 font-color-darkbrown">
          {productName}
        </h2>
        <h2 className="text-align-center font-weight-normal font-size-16 font-color-darkbrown">
          <span className="line-yellow">
            {getYearOfSecurityStartAt()}年加入フォーム
          </span>
        </h2>
      </header>
      <div className="header-triangle">
        <div></div>
      </div>
    </>
  );
};

export default Header;
