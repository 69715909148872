export enum CHOICE_OPTION {
  NONE = "",
  OK = "はい",
  NO = "いいえ",
}

export const ETC_OF_JOB_DETAIL = "その他";

export const EMPTY_MESSAGE = "選択してください";

export const PENDING_MESSAGE = "少々お待ちください";

export const MANUFACTURER_CODE = "55";
