/**
 * サイト·フッター
 *
 * @param props
 * @returns
 */
const Footer = (props: any) => {
  const { agency, email, phone } = props.data;

  return (
    <div className="footer-margin">
      <div className="main-container registration-number">
        登録番号：Ｂ-220184
      </div>
      <footer className="main-container">
        <div>
          <p className="font-size-12 line-height-20">
            この保険に関するお問い合わせはこちらまで
          </p>
          <p className="font-size-small">代理店・扱者名 {agency}</p>
          <p className="font-size-small">TEL {phone}</p>
          <p className="font-size-small">メールアドレス {email}</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
