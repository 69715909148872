import moment from "moment";
import ParsedUtil from "./ParsedUtil";

const DateUtil = {
  FORMAT: {
    JAPANESE_DATE: "YYYY年MM月DD日",
    JAPANESE_MONTH: "YYYY年MM月",
    JAPANESE_MONTH_AND_DATE: "MM月DD日",
    SLASH_DATE: "yyyy/MM/dd",
    SLASH_MONTH: "yyyy/MM",
    ONLY_NUMBER_DATE: "yyyyMMDD",
    ONLYNUMBER: "yyyyMMDDhhmm",
    HYPHEN_DATE: "yyyy-MM-DD",
  },
  convertDateFormat: (value: any, format?: string) => {
    // 値がない場合は、undefindが返却される。
    return (
      value && moment(value).format(format || DateUtil.FORMAT.JAPANESE_DATE)
    );
  },
  parsedResponseDateToDateType: (data: any) => {
    const parseData = JSON.parse(JSON.stringify(data));
    for (const [key, value] of Object.entries(data)) {
      if (!value) continue;
      if (typeof value === "object") {
        parseData[key] = DateUtil.parsedResponseDateToDateType(value);
      } else if (Array.isArray(value)) {
        parseData[key] = value.map((item) => {
          return DateUtil.parsedResponseDateToDateType(item);
        });
      } else if (DateUtil.isDateField(key)) {
        data[key] = ParsedUtil.parsedToDate(value)?.getTime();
        parseData[key] = ParsedUtil.parsedToDate(parseData[key])?.getTime();
      }
    }

    return parseData;
  },
  isDateField: (field: string) => {
    return (
      field.endsWith("At") ||
      field.endsWith("Date") ||
      field.endsWith("Birthday") ||
      field.endsWith("Birth") ||
      field.endsWith("Time")
    );
  },
  yearCodeList: (from: number, to: number, sort: "ASC" | "DESC" = "ASC") => {
    const yearList = [];

    switch (sort) {
      case "ASC": {
        for (let i = from; i <= to; i++) {
          yearList.push({ codeName: i.toString(), codeValue: i.toString() });
        }
        return yearList;
      }
      case "DESC": {
        for (let i = to; i >= from; i--) {
          yearList.push({ codeName: i.toString(), codeValue: i.toString() });
        }
        return yearList;
      }
    }
  },
  monthCodeList: (needPad: boolean = false) => {
    const monthList = [];
    for (let i = 1; i <= 12; i++) {
      if (needPad) {
        monthList.push({
          codeName: i.toString().padStart(2, '0'),
          codeValue: i.toString().padStart(2, '0'),
        });
      } else {
        monthList.push({
          codeName: i.toString(),
          codeValue: i.toString(),
        });
      }
    }
    return monthList;
  },
  dayCodeList: (year: number, month: number, needPad: boolean = false) => {
    const lastDay = new Date(
      year,
      month,
      0
    ).getDate();

    const dayList = [];
    for (let i = 1; i <= lastDay; i++) {
      if (needPad) {
        dayList.push({
          codeName: i.toString().padStart(2, '0'),
          codeValue: i.toString().padStart(2, '0'),
        });
      } else {
        dayList.push({
          codeName: i.toString(),
          codeValue: i.toString(),
        });
      }
    }
    return dayList;
  }
};
export default DateUtil;
