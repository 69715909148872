import { useEffect, useState } from "react";
import { useContextState } from "../../common/service/Context";
import DateUtil from "../../common/util/DateUtil";
import PageInfo from "../../common/constant/PageInfo";
import { PlanVO } from "../model/ProductModel";
import { application } from "../../common/service/AipomApiService";
import { ApplicationRequestVO } from "../model/ApplicationModel";
import { CHOICE_OPTION } from "../../common/enum/EnumForAipom";
import { SEX_CODE } from "../../common/constant/CodeForAipom";
import { COVERAGE_TYPES_CODE } from "../../common/constant/CodeForAipom";
import { Link } from "react-router-dom";
import { EMPTY_MESSAGE } from "../../common/enum/EnumForAipom";
import Navigator from "../common/Navigator";
import BaseModal from "../ui/base/BaseModal";
import { PENDING_MESSAGE } from "../../common/enum/EnumForAipom";
import InfoMessage from "../ui/InfoMessage";
import BaseLoader from "../ui/base/BaseLoader";
import PlanBoard from "../ui/PlanBoard";

const SUCCESS_MESSAGE = "Created";

/**
 * 入力内容確認
 *
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-10
 * @updatedAt : 2021-12-17
 */
const ApplicationConfirm = (props: any) => {
  const [isOpenedPdf, setIsOpenedPdf] = useState<boolean>(false);
  const [isAgreePDF, setIsAgreePDF] = useState<boolean>(false);
  const [isStartValidation, setIsStartValidation] = useState<boolean>(false);
  const [isDiscountOpenModal, setIsDiscountOpenModal] =
    useState<boolean>(false);
  const [isOpenCustomErrorModal, setIsOpenCustomErrorModal] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const {
    securityStartAt,
    securityEndAt,
    employeeLabel,
    requireOffice,
    disclosureStatementUrl,
    rate,
  } = props.data;

  const plans: Array<PlanVO> = props.data.plans;
  const statements: Array<string> = props.data.statements;

  const state = useContextState();

  useEffect(() => {
    if (props.currentPage !== PageInfo.path.application.confirm) {
      props.setCurrentPage(PageInfo.path.application.confirm);
    }
  });

  /**
   * PDFオープン
   */
  const onClickPdfButton = () => {
    setIsOpenedPdf(true);
    window.open(disclosureStatementUrl);
  };

  const onClickSummaryLink = () => {
    window.open("https://www.aig.co.jp/sonpo/lp/ind/newnormal/hosyougaiyou.html");
  };

  const onClickDiscountLink = () => {
    setIsDiscountOpenModal(!isDiscountOpenModal);
  };

  /**
   * 画面遷移
   *
   * @param page 画面
   */
  const onClickGoToPage = (
    page: "applicationInput" | "occupationInput" | "occupationPlan" | "notify" | "payment"
  ) => {
    let path = "/error";
    switch (page) {
      case "applicationInput":
        path = PageInfo.path.application.input + state.saleId;
        break;
      case "occupationInput":
        path = PageInfo.path.occupation.input + state.saleId;
        break;
      case "occupationPlan":
        path = PageInfo.path.occupation.plan + state.saleId;
        break;
      case "notify":
        path = PageInfo.path.application.notify + state.saleId;
        break;
      case "payment":
        path = PageInfo.path.application.payment + state.saleId;
    }

    props.history.push(path);
  };

  const getParams = () => {
    const tempContract = { ...state.contractor } as any;
    delete tempContract.prefecture;
    delete tempContract.municipalities;
    delete tempContract.streetNumber;
    delete tempContract.buildingName;

    const tempInsured = { ...state.insureds } as Array<any>;
    const tempParam = { ...tempInsured[0] };

    delete tempParam.jobName;
    delete tempParam.category;
    delete tempParam.yearOfBirth;
    delete tempParam.monthOfBirth;
    delete tempParam.dayOfBirth;
    delete tempParam.age;

    if (!requireOffice) {
      delete tempContract["officeCode"];
    }

    if (tempParam.otherContract && !tempParam.otherContract.company) {
      delete tempParam.otherContract;
    }

    const param = [] as Array<any>;

    param.push(tempParam);

    const tempSaleIdParams = state.saleId.split('&');
    let tempSaleId = "";

    for (let ii = 0; ii < tempSaleIdParams.length; ii++) {
      if (tempSaleIdParams[ii].startsWith('saleId'))
        tempSaleId = tempSaleIdParams[ii].replace("saleId=", "");
    }

    const params = {
      saleId: tempSaleId,
      planIds: state.planIds,
      coverageType: state.coverageType,
      contractor: tempContract,
      insureds: param,
      memberId: state.memberId,
    } as ApplicationRequestVO;

    return params;
  };

  /**
   * 申込受付
   * 成功すると、申請完了で遷移
   */
  const onClickApplication = async () => {
    setIsStartValidation(true);
    if (isPending || !isAgreePDF) {
      return;
    }

    setIsPending(true);
    const params = getParams();
    const { data, error } = await application(params);
    setIsPending(false);
    if (state.hasPaymentsType && error) {
      setIsOpenCustomErrorModal(true);
      return;
    }


    if (data && data === SUCCESS_MESSAGE) {
      window.scrollTo(0, 0);
      props.history.push(PageInfo.path.application.complete);
    }
  };

  /**
   * 選択したプラン情報の返却
   *
   * @param item 項目
   * @returns
   */
  const getPlanInfo = (item: "name" | "contents") => {
    if (!plans) {
      return;
    }
    const tempBasePlans = new Array<PlanVO>();
    let savedBasePlan = {} as PlanVO;

    (plans as Array<PlanVO>).forEach((plan) => {
      if (plan.isBase) {
        tempBasePlans.push(plan);
      }
    });

    tempBasePlans.forEach((plan) => {
      if (state.planIds.includes(plan.id)) {
        savedBasePlan = plan;
      }
    });

    return savedBasePlan[item];
  };

  /**
   * 選択したプランオプションの返却
   *
   * @returns
   */
  const getPlanOptions = () => {
    if (!plans) {
      return;
    }
    const tempPlanOptions = new Array<PlanVO>();
    const savedPlanOptions = new Array<PlanVO>();
    (plans as Array<PlanVO>).forEach((plan) => {
      if (!plan.isBase) {
        tempPlanOptions.push(plan);
      }
    });

    tempPlanOptions.forEach((plan) => {
      if (state.planIds.includes(plan.id)) {
        savedPlanOptions.push(plan);
      }
    });

    return savedPlanOptions ?? [];
  };

  /**
   * カード番号マスキング
   * 
   * @returns 
   */
  const getMaskedCardNo = () => {
    return state.cardInfo.cardno.replaceAll(/([0-9])/g, "X").slice(0, -4)
      .concat(state.cardInfo.cardno.slice(-4)).match(/[X0-9●]{1,4}/g)?.join(' - ');
  };

  if (!securityStartAt || !securityEndAt || !plans || !statements) {
    return <div></div>;
  }

  return (
    <article>
      <section className="main-container mgt24 mgb24">
        <Navigator currentPage={PageInfo.path.application.confirm} />
        <h2 className="font-color-blue font-weight-bold">
          {`${state.hasPaymentsType ? 7 : 6}.加入申込み前の確認`}
        </h2>
        <div className="mgb12">
          <h4 className="content-board-title font-weight-bold font-color-darkbrown">
            加入者情報（お名前等）
          </h4>
        </div>

        <div className="container-common-border border-line-blue">
          <div>
            <label className="font-weight-bold font-size-18">
              加入者名（漢字）
            </label>
            <p>{state.contractor.lastName + state.contractor.firstName}</p>
          </div>
          <div>
            <label className="font-weight-bold font-size-18">
              加入者名（カナ）
            </label>

            <p>
              {state.contractor.kanaLastName + state.contractor.kanaFirstName}
            </p>
          </div>
          <div>
            <label className="font-weight-bold font-size-18">加入者住所</label>
            <p>{state.contractor.zipcode}</p>
            <p>{state.contractor.address}</p>
          </div>
          <div>
            <label className="font-weight-bold font-size-18">
              自宅または携帯電話番号
            </label>
            <p>{state.contractor.phone}</p>
          </div>
          <label className="font-weight-bold font-size-18">
            {employeeLabel ?? "社員番号"}
          </label>
          <p>{state.contractor.employeeNumber}</p>

          {state.contractor.officeCode && (
            <div>
              <label className="font-weight-bold font-size-18">
                事業所番号
              </label>
              <p>{state.contractor.officeCode}</p>
            </div>
          )}

          <div>
            <label className="font-weight-bold font-size-18">
              メールアドレス
            </label>
            <p>{state.contractor.email}</p>
          </div>
        </div>
        <div className="displayFlex max-width640 mgt12 mgb12">
          <InfoMessage>
            <div>加入者が被保険者ご本人となります。</div>
          </InfoMessage>
        </div>
        <button
          onClick={() => onClickGoToPage("applicationInput")}
          className="button-common-blue  display-inline-middle"
        >
          内容を修正する
        </button>
      </section>

      <section className="content-board background-bage">
        <h4 className="content-board-title font-weight-bold font-color-darkbrown mgl8">
          加入者情報（告知事項等）
        </h4>
        <div className="content-board-content border-line-blue">
          <div>
            <label className="font-weight-bold font-size-18">
              職務内容（大分類）
            </label>
            <p>{state.insureds[0].jobName}</p>
          </div>
          <div>
            <label className="font-weight-bold font-size-18">
              職務内容詳細
            </label>
            <p>{state.insureds[0].job}</p>
          </div>
          <div>
            <label className="font-weight-bold font-size-18">職種級別</label>
            <p>{state.insureds[0].category}</p>
          </div>
          <div>
            <label className="font-weight-bold font-size-18">生年月日</label>
            <p>{`${state.insureds[0].yearOfBirth}年 ${state.insureds[0].monthOfBirth}月 ${state.insureds[0].dayOfBirth}日`}</p>
          </div>
          <div>
            <label className="font-weight-bold font-size-18">年齢</label>
            <p>{`${state.insureds[0].age} 歳`}</p>
          </div>
          <div>
            <label className="font-weight-bold font-size-18">性別</label>
            <p>
              {
                SEX_CODE.find(
                  (code) => code.codeValue === state.insureds[0].sex
                )?.codeName
              }
            </p>
          </div>
        </div>
        <button
          onClick={() => onClickGoToPage("occupationInput")}
          className="button-common-blue mgt24 display-inline-middle"
        >
          内容を修正する
        </button>
        {state.hasPaymentsType && (
          <>
            <div className="content-board-content border-line-blue mgt24">
              <div>
                <label className="font-weight-bold font-size-18">
                  カード番号
                </label>
                <p>{getMaskedCardNo()}</p>
              </div>
              <div>
                <label className="font-weight-bold font-size-18">
                  クレジットカード名義人
                </label>
                <p>{state.cardInfo.holdername.toLocaleUpperCase()}</p>
              </div>
              <div>
                <label className="font-weight-bold font-size-18">
                  カード有効期限（月/年）
                </label>
                <p>{`${state.cardInfo.monthOfExpire}/${state.cardInfo.yearOfExpire.slice(-2)}`}</p>
              </div>
            </div>
            <button
              onClick={() => onClickGoToPage("payment")}
              className="button-common-blue mgt24 display-inline-middle"
            >
              内容を修正する
            </button>
          </>)}
      </section>

      <section className="main-container mgt40 mgb40">
        <div className="mgb12">
          <h4 className="content-board-title font-weight-bold font-color-darkbrown">
            契約タイプ（被保険者の範囲）・加入プラン情報
          </h4>
        </div>

        <div className="max-width640 min-width640">
          <label className="coverage-type-label-active">
            <span className={"radio-button-text font-color-orange"}>
              {
                COVERAGE_TYPES_CODE.find(
                  (code) => code.codeValue === state.coverageType
                )?.codeName
              }
            </span>
          </label>
        </div>
        <div className="plan-board-active">
          <div className="plan-board-info">
            <div>
              <span
                className={
                  "radio-button-text font-color-orange plan-title-info"
                }
              >
                <span className={"font-weight-bold"}>
                  {getPlanInfo("name")}
                </span>
                <span className="font-color-darkbrown font-size-16">
                  団体割引率{rate}適用
                </span>
              </span>
            </div>
          </div>

          <div>
            <div>
              <div className="plan-board-detail">
                <PlanBoard
                  isShowingOption={state.planIds.includes((getPlanOptions() as Array<any>)[0]?.id)}
                  isCheckedOption={true}
                />
                <div className="plan-board-amount">
                  <div className="plan-board-amount-title">
                    ご選択内容は以下です
                    <div className="plan-board-amount-triangle"></div>
                  </div>
                  <div className="plan-board-amount-content">
                    <span className="font-size-14 font-color-navy font-weight-bold">
                      ご選択プラン
                    </span>
                    <span className="font-size-14 font-color-orange font-weight-bold">
                      {!state.planIds.includes(
                        (getPlanOptions() as Array<any>)[0]?.id
                      )
                        ? "ベースプラン"
                        : "アップグレードプラン"}
                    </span>
                  </div>

                  <div className="plan-board-amount-content">
                    <span className="font-size-14 font-color-navy font-weight-bold">
                      {state.hasPaymentsType
                        ? "一時払保険料"
                        : "月払保険料"}
                    </span>
                    <span>
                      <span className="font-color-orange font-weight-bold font-size-22">
                        {Number.parseInt(state.amount).toLocaleString("ja-JP")}
                      </span>
                      <span className="font-size-14 font-weight-normal font-color-darkbrown">
                        &nbsp;円
                      </span>
                    </span>
                  </div>
                </div>
                <p className="warning">
                  <span>（注）</span>
                  <span>
                    夫婦型、家族型、本人・親族型の契約タイプを選択された場合、死亡、後遺障害、入院、手術、通院の各保険金額は、被保険者ごとに上記保険金額となります。
                    <br />
                    携行品損害保険金額は、被保険者全員合算で、保険期間を通じてご加入の保険金額が限度額となります。
                    <br />
                    個人賠償責任補償は、１事故につきご加入の保険金額が限度額となります。
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={() => onClickGoToPage("occupationPlan")}
            className="button-common-blue mgt24 display-inline-middle"
          >
            プランを再選択する
          </button>
        </div>
      </section>

      <section className="content-board background-bage">
        <h4 className="content-board-title font-weight-bold font-color-darkbrown no-mgt mgl8">
          他の保険契約について（告知事項）
        </h4>
        <div className="content-board-content border-line-blue">
          <div>
            <label className="font-weight-bold font-size-18">
              同一の補償を提供する他の保険契約にご加入ですか ?
            </label>
            <p>
              {state.notifyInfo.hasOtherContract
                ? CHOICE_OPTION.OK
                : CHOICE_OPTION.NO}
            </p>
          </div>
          {state.notifyInfo.hasOtherContract && (
            <>
              <div>
                <label className="font-weight-bold font-size-18">
                  保険会社名
                </label>
                <p>{state.insureds[0].otherContract.company}</p>
              </div>
              <div>
                <label className="font-weight-bold font-size-18">
                  保険種類
                </label>
                <p>{state.insureds[0].otherContract.kind}</p>
              </div>
              {state.insureds[0].otherContract.insurance1 && (
                <div>
                  <label className="font-weight-bold font-size-18">
                    死亡・後遺障害保険金額合計
                  </label>
                  <p>
                    {Number.parseInt(
                      state.insureds[0].otherContract.insurance1
                    ).toLocaleString("ja-JP") + "万円"}
                  </p>
                </div>
              )}
              {state.insureds[0].otherContract.insurance2 && (
                <div>
                  <label className="font-weight-bold font-size-18">
                    入院保険金日額合計
                  </label>
                  <p>
                    {Number.parseInt(
                      state.insureds[0].otherContract.insurance2
                    ).toLocaleString("ja-JP") + "円"}
                  </p>
                </div>
              )}
            </>
          )}
          {statements.map((statement, index) => {
            if (!statement.trim()) {
              return <div key={"statement_" + index}></div>;
            }
            return (
              <div key={"statement_" + index}>
                <label className="font-weight-bold">{statement}</label>
                <p>
                  {state.notifyInfo.statementsResults[index]
                    ? CHOICE_OPTION.OK
                    : CHOICE_OPTION.NO}
                </p>
              </div>
            );
          })}
        </div>
        <button
          onClick={() => onClickGoToPage("notify")}
          className="button-common-blue mgt24 display-inline-middle"
        >
          内容を修正する
        </button>
      </section>

      <section className="main-container mgt40 mgb40">
        <h4 className="content-board-title font-weight-bold font-color-darkbrown">
          保険期間
        </h4>

        <div className="content-board-content border-line-blue  no-margin">
          <div>
            <p className="font-color-navy font-size-18 font-weight-normal">
              {securityStartAt
                ? DateUtil.convertDateFormat(
                  securityStartAt,
                  "YYYY年MM月DD日"
                ) + " 0時から"
                : PENDING_MESSAGE}
            </p>
            <p className="font-color-navy font-size-18 font-weight-normal">
              {securityEndAt
                ? DateUtil.convertDateFormat(securityEndAt, "YYYY年MM月DD日") +
                " 16時まで"
                : PENDING_MESSAGE}
            </p>
          </div>
          <div className="board-info-text-with-subTitle max-width640 mgt24">
            <div className="board-info-text max-width640 ">
              <InfoMessage>
                <div className="font-weight-bold font-color-darkbrown font-size-18">
                  上記は、
                  <span className="font-color-orange font-weight-bold">
                    ご案内チラシ記載の申込締切
                  </span>
                  までにお申し込みいただいた場合の保険期間です。 <br />
                  申込締切を過ぎてお申し込みをされる場合、保険期間開始日が異なります。詳しくは下記お問合せ先までご確認ください。（保険期間終了日は上記に同じです。）
                </div>
              </InfoMessage>
            </div>
          </div>
        </div>
      </section>
      <section className="content-board background-bage">
        <h4 className="content-board-title font-weight-bold font-color-darkbrown mgl8">
          その他の注意点
        </h4>
        <div className="content-board-content border-line-blue">
          <div className="board-info-text-with-subTitle max-width640">
            <div className="board-info-text max-width640">
              <InfoMessage>
                <div>
                  <div className="font-weight-bold font-color-darkbrown font-size-18">
                    各加入プランの保険料には、団体割引{rate}
                    を適用しています。
                    {!state.hasPaymentsType &&
                      "団体割引率は下表のとおり被保険者数に応じて変更となります。"

                    }
                    <br />
                    保険期間開始日時点の被保険者数によっては、団体割引率が変わり保険料が変更となりますので、あらかじめご了承ください。
                  </div>
                </div>
              </InfoMessage>
            </div>

            {!state.hasPaymentsType &&
              <div className="board-table mgt12 mgb12 ">
                <table className="font-color-blue">
                  <thead>
                    <tr>
                      <th>団体割引率</th>
                      <th>被保険者数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>5%</td>
                      <td>20名~99名</td>
                    </tr>
                    <tr>
                      <td>10%</td>
                      <td>100名~499名</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
            <label
              className="link_label mgt24 mgb24 mgl-auto"
              onClick={onClickDiscountLink}
            >
              <div></div>
              <label>団体割引率ごとの保険料</label>
            </label>
            <div className="board-info-text max-width640">
              <InfoMessage>
                <div className="font-weight-bold font-color-darkbrown font-size-18 mgb24">
                  申込締切時点で被保険者数が20名に達しなかった場合、この団体保険契約は成立せず、加入申込みも同様に不成立となりますので、あらかじめご了承ください。
                </div>
              </InfoMessage>
            </div>
            <div className="board-info-text max-width640">
              <InfoMessage>
                <div className="font-weight-bold font-color-darkbrown font-size-18 mgb24">
                  契約者である団体は、加入フォームに入力された個人情報をこの保険の引受保険会社に提供します。引受保険会社における個人情報の取扱いにつきましては、重要事項説明書にてご確認ください。
                </div>
              </InfoMessage>
            </div>
            <div className="board-info-text max-width640">
              <InfoMessage>
                <div className="font-weight-bold font-color-darkbrown font-size-18 mgb8">
                  更新時に、加入者または契約団体から特段の申し出がない限り、自動的に更新手続きが取られます。被保険者数の増減による団体割引率変更のために保険料に変更が発生した場合も自動更新されます。
                </div>
              </InfoMessage>
            </div>
          </div>
        </div>
      </section>

      <section className="main-container mgt40 mgb40">
        <h4 className="content-board-title font-weight-bold font-color-darkbrown">
          重要事項説明書の確認
        </h4>
        <div className="font-weight-bold font-color-darkbrown font-size-18 mgb8">
          重要事項説明書PDFを開いて、内容を確認してください。
          <br />
          ご確認後、「重要事項説明書を確認しました」を選択してください。
        </div>

        <button
          onClick={onClickPdfButton}
          className="button-common-blue mgt24 display-inline "
        >
          重要事項説明書PDF
        </button>
        <div className="mobile-align-center">
          <p className="warning">
            <span>印刷もしくはご自身の端末に保存してください。</span>
          </p>
        </div>
        <label className="link_label mgt24 mgb24" onClick={onClickSummaryLink}>
          <div></div>
          <label>補償概要</label>
        </label>
        <label
          className={
            isAgreePDF
              ? "base-radio-label-active max-width340 mgt24"
              : "base-radio-label max-width340 mgt24"
          }
        >
          {isAgreePDF ? (
            <div className="radio-button-check-active">
              <div className="radio-button-check-item"></div>
            </div>
          ) : (
            <div className="radio-button-check">
              <div></div>
            </div>
          )}
          <span
            className={
              isAgreePDF
                ? "radio-button-text font-color-orange"
                : "radio-button-text font-color-darkbrown"
            }
          >
            重要事項説明書を確認しました
          </span>
          <input
            className="visibility-collapse"
            type="checkbox"
            disabled={!isOpenedPdf}
            onChange={(e) => {
              setIsAgreePDF(e.target.checked);
            }}
          />
        </label>
        {isStartValidation && !isAgreePDF && (
          <div className="base-input-area">
            <div>{EMPTY_MESSAGE}</div>
          </div>
        )}
      </section>

      <div className="board-button-two">
        <div>
          <button
            className={
              isPending
                ? "button-application-orange-disable"
                : "button-application-orange"
            }
            onClick={onClickApplication}
          >
            <div>
              <span></span>
              <span>この内容で申し込む</span>
              <span className="button-arrow"></span>
            </div>
          </button>
        </div>

        <div className="board-link">
          <Link
            to={state.hasPaymentsType ? PageInfo.path.application.payment + state.saleId : PageInfo.path.application.notify + state.saleId}
          >
            戻る
          </Link>
        </div>
      </div>
      <section>
        <BaseModal
          isOpen={isDiscountOpenModal}
          setIsOpen={setIsDiscountOpenModal}
        >
          <img
            className="group-discount"
            src={state.hasPaymentsType
              ? "/resources/images/group_discount_has_payments.png"
              : "/resources/images/group_discount.jpg"}
            alt="団体割引別保険料"
          />
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsDiscountOpenModal(!isDiscountOpenModal);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseModal isOpen={isOpenCustomErrorModal} setIsOpen={setIsOpenCustomErrorModal}>
          <div className="font-weight-bold font-size-18">
            カード決済の処理に失敗しました。
            <br />
            入力された内容をご確認ください。
          </div>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              onClickGoToPage("payment");
            }}
          >
            入力画面に戻る
          </button>
        </BaseModal>
        <BaseLoader isOpen={state.hasPaymentsType && isPending}>
          <p className="font-weight-bold font-size-18">決済中です</p>
        </BaseLoader>
      </section>
    </article>
  );
};

export default ApplicationConfirm;
