/**
 * 共通ラジオボタンコンポーネント
 *
 * @param param
 * @returns
 */
const BaseInputRadio = ({
  props,
  label,
}: {
  props: {
    name: string;
    value: string;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    onChange?: (e: any) => void;
  };
  label: string;
}) => {
  return (
    <label>
      <input type="radio" {...props} />
      {label}
    </label>
  );
};
export default BaseInputRadio;
