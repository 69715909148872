/**
 * 申込完了
 *
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-10
 * @updatedAt : 2021-12-17
 */
const Complete = (props: any) => {
  return (
    <article>
      <section className="main-container mgt24 mgb24 ">
        <h2 className="font-color-blue font-weight-bold">
          お申込みいただき、ありがとうございました
        </h2>
        <div className="container-common-border border-line-blue">
          <p className="font-color-navy font-size-18 font-weight-bold">
            入力いただいたメールアドレスに、お申込内容を送信しました。
          </p>
          <p className="font-color-navy font-size-18 font-weight-bold">
            メールが届かない場合は、お手数ですが下記お問合せ先までご連絡をお願いいたします。
          </p>
          <p className="font-color-navy font-size-18 font-weight-bold">
            お申込みはこれで完了です。このまま画面を閉じてください。
          </p>
        </div>
      </section>
    </article>
  );
};

export default Complete;
