import { useRef } from "react";
import PageInfo from "../../common/constant/PageInfo";
import { useContextState } from "../../common/service/Context";

export const PAGE_LIST: Array<string> = [
  PageInfo.path.application.preConfirm,
  PageInfo.path.occupation.input,
  PageInfo.path.occupation.plan,
  PageInfo.path.application.input,
  PageInfo.path.application.notify,
  PageInfo.path.application.payment,
  PageInfo.path.application.confirm,
];

const NAVIGATOR_CONFIG = [
  {
    title: "ご加入前確認",
    viewBox: "0 0 111 40",
    pathD:
      "M 0 0 L 105.4494400024414 0 L 111 20 L 105.4494400024414 40 L 0 40 L 0 0 Z",
  },
  {
    title: "加入者告知入力",
    viewBox: "0 0 125 40",
    pathD:
      "M 0 0 L 118.540221737646 0 L 125 20 L 118.540221737646 40 L 0 40 L 0 0 Z",
  },
  {
    title: "契約タイプ・プラン選択",
    viewBox: "0 0 181 40",
    pathD:
      "M 0 0 L 171.64624107611138 0 L 181 20 L 171.64624107611138 40 L 0 40 L 0 0 Z",
  },
  {
    title: "加入者情報入力",
    viewBox: "0 0 125 40",
    pathD:
      "M 0 0 L 118.540221737646 0 L 125 20 L 118.540221737646 40 L 0 40 L 0 0 Z",
  },
  {
    title: "告知事項入力",
    viewBox: "0 0 111 40",
    pathD:
      "M 0 0 L 105.4494400024414 0 L 111 20 L 105.4494400024414 40 L 0 40 L 0 0 Z",
  },
  {
    title: "お支払い方法",
    viewBox: "0 0 111 40",
    pathD:
      "M 0 0 L 131.81672657226233 0 L 139 20 L 131.81672657226233 40 L 0 40 L 0 0 Z",
  },
  {
    title: "加入申込み前確認",
    viewBox: "0 0 139 40",
    pathD:
      "M 0 0 L 131.81672657226233 0 L 139 20 L 131.81672657226233 40 L 0 40 L 0 0 Z",
  },
];

const Navigator = (props: any) => {
  const { currentPage } = props;
  const currentPageIndex = useRef<number>(-1);
  const state = useContextState();

  const getPageList = () => {
    return state.hasPaymentsType ? PAGE_LIST : PAGE_LIST.filter(page => {
      return page !== PageInfo.path.application.payment;
    });
  };

  return (
    <div className="navigator-container">
      {getPageList().map((page, index) => {
        const isCurrentPage = page === currentPage;
        const title = `${index + 1}.${NAVIGATOR_CONFIG[!state.hasPaymentsType && index === 5 ? index + 1 : index].title}`;
        if (isCurrentPage) {
          currentPageIndex.current = index;
        }
        if (getPageList().includes(currentPage)) {
          return (
            <div key={"navigator_" + index}>
              {isCurrentPage ? (
                <>
                  <div className="navigator-item navigator-item-orange">
                    <div className="navigator-page">
                      &nbsp;{title}
                    </div>
                  </div>
                  <div className="navigator-item-triangle"></div>
                </>
              ) : (
                <>
                  <div
                    className={
                      currentPageIndex.current < index
                        ? "navigator-item navigator-item-gray"
                        : "navigator-item navigator-item-blue"
                    }
                  >
                    <div className="navigator-page">{index + 1}</div>
                  </div>
                  <div
                    className={
                      currentPageIndex.current < index
                        ? "navigator-item-triangle-gray"
                        : "navigator-item-triangle-blue"
                    }
                  ></div>
                </>
              )}
            </div>
          );
        } else {
          return <div key={"navigator_" + index}></div>;
        }
      })}
    </div>
  );
};

export default Navigator;
