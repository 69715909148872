import { useCallback, useEffect, useState } from "react";
import {
  useContextState,
  useContextDispatch,
} from "../../common/service/Context";
import PassedPageManagementUtil from "../../common/util/PassedPageManagementUtil";
import ValidationUtil from "../../common/util/ValidationUtil";
import PageInfo from "../../common/constant/PageInfo";
import { ValidationVO } from "../model/CommonModel";
import { CHOICE_OPTION } from "../../common/enum/EnumForAipom";
import { Link } from "react-router-dom";
import BaseModal from "../ui/base/BaseModal";
import BaseInputText from "../ui/base/BaseInputText";
import BaseInputRadio from "../ui/base/BaseInputRadio";
import Navigator from "../common/Navigator";

const REQUIRE_LIST = ["company", "kind"];

const VALIDATION_OPTIONS = {
  company: { maxLength: 10 },
  kind: { maxLength: 10 },
  insurance1: { maxLength: 10, format: "number" },
  insurance2: { maxLength: 10, format: "number" },
} as any;

const defaultData = {
  formData: {
    company: "",
    kind: "",
    insurance1: "",
    insurance2: "",
  },
};

/**
 * 告知
 *
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-06
 * @updatedAt : 2021-12-17
 */
const Notify = (props: any) => {
  const [formData, setFormData] = useState<any>(defaultData.formData);
  const [hasOtherContract, setHasOtherContract] = useState<string>(
    CHOICE_OPTION.NONE
  );
  const [statementsResults, setStatementsResults] = useState<Array<boolean>>(
    []
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);
  const [isStartValidation, setIsStartValidation] = useState<boolean>(false);
  const [memorizedStatements, setMemorizedStatements] = useState<Array<string>>(
    []
  );

  const statements: Array<string> = props.data.statements;

  const state = useContextState();
  const dispatch = useContextDispatch();


  useEffect(() => {
    if (props.currentPage !== PageInfo.path.application.notify) {
      window.scrollTo(0, 0);
      props.setCurrentPage(PageInfo.path.application.notify);
    }
    hasOtherContract === CHOICE_OPTION.NONE &&
      PassedPageManagementUtil.isPassedPage(PageInfo.path.application.notify) &&
      setState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statementsResults, hasOtherContract, statements, formData]);

  useEffect(() => {
    getStatements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statements]);

  /**
   * 告知内容結果セッティング
   *
   * @param index 修正する告知内容番号
   * @param value 修正すべき内容
   */
  const onChangeStatementResult = (index: number, value: boolean) => {
    let tempList = [...statementsResults] as Array<boolean>;
    tempList[index] = value;
    setStatementsResults(tempList);
  };

  /**
   * 入力内容確認で画面遷移
   *
   * @returns
   */
  const onClickNextButton = () => {
    if (
      memorizedStatements.length !== statementsResults?.length ||
      statementsResults?.some((result) => !result)
    ) {
      setIsStartValidation(true);
      setIsInvalidData(true);
      return;
    }

    if (hasOtherContract === CHOICE_OPTION.OK) {
      if (
        !ValidationUtil.hasValues(formData, REQUIRE_LIST) ||
        (!ValidationUtil.hasValue(formData.insurance1) &&
          !ValidationUtil.hasValue(formData.insurance2)) ||
        !isValidData()
      ) {
        setIsStartValidation(true);
        setIsInvalidData(true);
        return;
      }
    }
    setContext();
    PassedPageManagementUtil.setPassedPageList(
      PageInfo.path.application.notify
    );

    props.history.push(
      state.hasPaymentsType ? PageInfo.path.application.payment + state.saleId : PageInfo.path.application.confirm + state.saleId
    );

  };

  /**
   * 修正時データセッティング
   */
  const setState = () => {
    if (!state.insureds[0].otherContract) {
      return;
    }
    const stateData = {
      company: state.insureds[0].otherContract.company,
      kind: state.insureds[0].otherContract.kind,
      insurance1: state.insureds[0].otherContract.insurance1,
      insurance2: state.insureds[0].otherContract.insurance2,
    };

    const chioce = state.notifyInfo.hasOtherContract
      ? CHOICE_OPTION.OK
      : CHOICE_OPTION.NO;
    setStatementsResults(state.notifyInfo.statementsResults);
    setHasOtherContract(chioce);
    setFormData(stateData);
  };

  /**
   * データ全域ステートに保存
   */
  const setContext = () => {
    let tempInsured = { ...state.insureds };
    let tempOtherContract = {} as any;
    if (hasOtherContract === CHOICE_OPTION.OK) {
      tempOtherContract = {
        company: formData.company,
        kind: formData.kind,
        insurance1: formData.insurance1,
        insurance2: formData.insurance2,
      };
      tempInsured[0].otherContract = tempOtherContract;
    } else {
      tempOtherContract = {
        company: "",
        kind: "",
        insurance1: "",
        insurance2: "",
      };
      tempInsured[0].otherContract = tempOtherContract;
    }

    const tempNotify = {
      hasOtherContract: hasOtherContract === CHOICE_OPTION.OK,
      statementsResults: statementsResults,
    };

    dispatch({ type: "SET_INSURED", insureds: tempInsured });
    dispatch({ type: "SET_NOTIFY_INFO", notifyInfo: tempNotify });
  };

  /**
   * 有効性検査
   *
   * @returns 有効性検査結果
   */
  const isValidData = () => {
    let result = true;

    Object.entries(formData).forEach(([key, value]) => {
      const validationOption = VALIDATION_OPTIONS[key] as ValidationVO;
      if (
        validationOption &&
        value &&
        ValidationUtil.validationByOption(value, validationOption)
      ) {
        result = false;
      }
    });

    return result;
  };

  /**
   * 告知事項リスト整理
   */
  const getStatements = useCallback(() => {
    if (!statements) {
      return [];
    }

    setMemorizedStatements(statements.filter((statement) => statement.trim()));
  }, [statements]);

  if (!statements) {
    return <div></div>;
  }
  return (
    <article>
      <section className="main-container mgt24 mgb24">
        <Navigator currentPage={PageInfo.path.application.notify} />
        <h2 className="font-color-blue font-weight-bold">
          5.他の保険契約について（告知事項）
        </h2>
        <div className="mgb12">
          <h4 className="content-board-title font-weight-bold font-color-darkbrown">
            同一の補償を提供する他の保険契約
            <span className="font-size-small">（注）</span>
            にご加入されていますか？
            <br />
            （「多重契約による保険金詐欺防止」のためにおたずねするものです。）
          </h4>
        </div>
        <p className="warning">
          <span>（注)</span>
          <span>
            「同一の補償を提供する他の保険契約」とは、他の傷害総合保険、普通傷害保険、家族傷害保険、グループ傷害保険、交通事故傷害保険、ファミリー交通傷害保険、ライフスタイル傷害保険、業務災害総合保険および同一の補償を提供する保険（共済を含みます）をいいます。
          </span>
        </p>
        <div>
          <label
            className={
              hasOtherContract === CHOICE_OPTION.OK
                ? "base-radio-label-active max-width340"
                : "base-radio-label max-width340"
            }
          >
            {hasOtherContract === CHOICE_OPTION.OK ? (
              <div className="radio-button-check-active">
                <div className="radio-button-check-item"></div>
              </div>
            ) : (
              <div className="radio-button-check">
                <div></div>
              </div>
            )}
            <span
              className={
                hasOtherContract === CHOICE_OPTION.OK
                  ? "radio-button-text font-color-orange"
                  : "radio-button-text font-color-darkbrown"
              }
            >
              {"はい"}
            </span>
            <BaseInputRadio
              props={{
                name: "hasOtherContractRadio",
                value: "",
                className: "visibility-collapse",
                checked: hasOtherContract === CHOICE_OPTION.OK,
                onChange: () => {
                  setHasOtherContract(CHOICE_OPTION.OK);
                },
              }}
              label=""
            />
          </label>

          <label
            className={
              hasOtherContract === CHOICE_OPTION.NO
                ? "base-radio-label-active max-width340"
                : "base-radio-label max-width340"
            }
          >
            {hasOtherContract === CHOICE_OPTION.NO ? (
              <div className="radio-button-check-active">
                <div className="radio-button-check-item"></div>
              </div>
            ) : (
              <div className="radio-button-check">
                <div></div>
              </div>
            )}
            <span
              className={
                hasOtherContract === CHOICE_OPTION.NO
                  ? "radio-button-text font-color-orange"
                  : "radio-button-text font-color-darkbrown"
              }
            >
              {"いいえ"}
            </span>
            <BaseInputRadio
              props={{
                name: "hasOtherContractRadio",
                value: "",
                className: "visibility-collapse",
                checked: hasOtherContract === CHOICE_OPTION.NO,
                onChange: () => {
                  setHasOtherContract(CHOICE_OPTION.NO);
                },
              }}
              label=""
            />
          </label>
        </div>
      </section>

      {hasOtherContract === CHOICE_OPTION.OK && (
        <section className="content-board background-bage">
          <h4 className="content-board-title font-weight-bold font-color-darkbrown no-mgt mgl8">
            「はい」を選択した場合、次の項目を入力してください
          </h4>
          <label className="link_label mgt24 mgb24">
            <div></div>
            <label
              onClick={() => {
                setIsModalOpen(!isModalOpen);
              }}
            >
              複数加入している場合は
            </label>
          </label>

          <div className="content-board-content border-line-blue">
            <div>
              <label className="font-weight-bold font-size-18">
                保険会社名
              </label>
              <label className="required-label">必須</label>
              <div className="input-text-width-95 max-width400 mgt8">
                <BaseInputText
                  value={formData.company}
                  onChange={(e) => {
                    setFormData({ ...formData, company: e.target.value });
                  }}
                  validationOptions={VALIDATION_OPTIONS.company}
                  isStartValidation={isStartValidation}
                  className="base-input-text-long"
                />
              </div>
              <p className="warning">
                <span>略称での入力も可能です。</span>
              </p>
            </div>
            <div>
              <label className="font-weight-bold font-size-18">保険種類</label>
              <label className="required-label">必須</label>
              <div className="input-text-width-95 max-width264 mgt8">
                <BaseInputText
                  value={formData.kind}
                  onChange={(e) => {
                    setFormData({ ...formData, kind: e.target.value });
                  }}
                  validationOptions={VALIDATION_OPTIONS.company}
                  isStartValidation={isStartValidation}
                  className="base-input-text-long"
                />
              </div>
              <p className="warning">
                <span>不明な場合「ケガの保険」と入力してください。</span>
              </p>
            </div>
            <div>
              <label className="font-weight-bold font-size-18">
                死亡・後遺障害保険金額合計
              </label>
              <label className="required-label">必須</label>
              <div className="max-width180 mgt8 ">
                <BaseInputText
                  value={formData.insurance1}
                  onChange={(e) => {
                    setFormData({ ...formData, insurance1: e.target.value });
                  }}
                  validationOptions={VALIDATION_OPTIONS.insurance1}
                  isStartValidation={isStartValidation}
                  disableEmptyValueCheck={formData.insurance2}
                />
                <span>万円</span>
              </div>

              <p className="warning">
                <span>
                  死亡・後遺障害保険金額、入院保険金日額の両方またはどちらか一方に入力してください。
                </span>
              </p>
            </div>
            <div>
              <label className="font-weight-bold font-size-18">
                入院保険金日額合計
              </label>
              <label className="required-label">必須</label>
              <div className="max-width180 mgt8">
                <BaseInputText
                  value={formData.insurance2}
                  onChange={(e) => {
                    setFormData({ ...formData, insurance2: e.target.value });
                  }}
                  validationOptions={VALIDATION_OPTIONS.insurance2}
                  isStartValidation={isStartValidation}
                  disableEmptyValueCheck={formData.insurance1}
                />
                <span>円</span>
              </div>
              <p className="warning">
                <span>
                  死亡・後遺障害保険金額、入院保険金日額の両方またはどちらか一方に入力してください。
                </span>
              </p>
            </div>
          </div>
          <div className="max-width720">
            <p className="warning mgr1em">
              <span>（注）</span>
              <span>
                各保険金額・日額は、引受の限度額があります。また、既に他の傷害保険契約を契約している場合には、保険金額・日額を制限させていただくことがあります。ご入力いただいた内容をもとに、取扱代理店・扱者にて確認を行い、限度額を超えている場合は、ご連絡いたしますので、あらかじめご了承ください。
              </span>
            </p>
          </div>
        </section>
      )}
      {hasOtherContract !== CHOICE_OPTION.NONE && (
        <>
          {memorizedStatements?.length > 0 && (
            <section>
              <h2 className="font-color-blue">健康状態に関する告知事項</h2>
              {memorizedStatements?.map((statement, index) => {
                return (
                  <div key={"statement_" + index}>
                    <div>
                      <label className="font-weight-bold displayFlex">
                        <img
                          src="/resources/images/ico_help_sp.png"
                          alt=""
                          className="mgr10"
                        />
                        {statement}
                      </label>
                    </div>
                    <label
                      className={
                        statementsResults[index]
                          ? "base-radio-label-active max-width340"
                          : "base-radio-label max-width340"
                      }
                    >
                      {statementsResults[index] ? (
                        <div className="radio-button-check-active">
                          <div className="radio-button-check-item"></div>
                        </div>
                      ) : (
                        <div className="radio-button-check">
                          <div></div>
                        </div>
                      )}

                      <span
                        className={
                          statementsResults[index]
                            ? "radio-button-text font-color-orange"
                            : "radio-button-text font-color-darkbrown"
                        }
                      >
                        はい
                      </span>
                      <input
                        type="radio"
                        className="visibility-collapse"
                        name={"statement_" + index}
                        onChange={() => {
                          onChangeStatementResult(index, true);
                        }}
                        defaultChecked={statementsResults[index]}
                      />
                    </label>

                    <label
                      className={
                        statementsResults[index]
                          ? "base-radio-label max-width340"
                          : "base-radio-label-active max-width340"
                      }
                    >
                      {statementsResults[index] ? (
                        <div className="radio-button-check">
                          <div></div>
                        </div>
                      ) : (
                        <div className="radio-button-check-active">
                          <div className="radio-button-check-item"></div>
                        </div>
                      )}

                      <span
                        className={
                          statementsResults[index]
                            ? "radio-button-text font-color-darkbrown"
                            : "radio-button-text font-color-orange"
                        }
                      >
                        いいえ
                      </span>
                      <input
                        type="radio"
                        className="visibility-collapse"
                        name={"statement_" + index}
                        onClick={() => {
                          onChangeStatementResult(index, false);
                        }}
                        defaultChecked={!statementsResults[index]}
                      />
                    </label>
                  </div>
                );
              })}
            </section>
          )}

          <div className="board-button-two">
            <div>
              <button
                className="button-confirm-orange"
                onClick={onClickNextButton}
              >
                <div>
                  <span></span>
                  <span>次へ</span>
                  <span className="button-arrow"></span>
                </div>
              </button>
            </div>
            <div className="board-link">
              <Link
                to={PageInfo.path.application.input + state.saleId}
              >
                戻る
              </Link>
            </div>
          </div>
        </>
      )}
      <section>
        <BaseModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
          <h4>同一の危険を補償する他の保険契約に複数ご加入の場合</h4>
          <p>
            保険会社名・保険種類 :
            <br />
            ご加入中の保険契約のうち保険金額が最も大きい保険契約の保険会社名・保険種類を入力してください。
            <br />
            <br />
            保険金額合計 : <br />
            ご加入中の保険契約全ての保険金額を合算して入力してください。
          </p>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsModalOpen(!isModalOpen);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseModal isOpen={isInvalidData} setIsOpen={setIsInvalidData}>
          <h4>入力項目を確認してください。</h4>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsInvalidData(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
      </section>
    </article>
  );
};

export default Notify;
