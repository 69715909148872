import { CodeVO } from "../../component/model/CommonModel";

const SEX_CODE = [
  { codeName: "男性", codeValue: "1" },
  { codeName: "女性", codeValue: "2" },
] as Array<CodeVO>;

const COVERAGE_TYPES_CODE = [
  { codeName: "個人型（ひとりでパスポート）", codeValue: "Individual" },
  { codeName: "夫婦型（夫婦でパスポート）", codeValue: "Couple" },
  { codeName: "家族型（家族でパスポートＡ）", codeValue: "Family" },
  { codeName: "本人・親族型（家族でパスポートＢ）", codeValue: "Relatives" },
];

export { SEX_CODE, COVERAGE_TYPES_CODE };
