import { createContext, Dispatch, useContext, useReducer } from "react";
import {
  ContractorVO,
  InsuredVO,
  OtherContractVO,
  NotifyInfoVO,
} from "../../component/model/ApplicationModel";
import { CardInfoVO } from "../../component/model/SalesModel";

const defaultData = {
  saleId: "",
  planIds: [],
  coverageType: "",
  contractor: {
    firstName: "",
    lastName: "",
    kanaFirstName: "",
    kanaLastName: "",
    zipcode: "",
    address: "",
    phone: "",
    employeeNumber: "",
    officeCode: "",
    email: "",
    prefecture: "",
    municipalities: "",
    streetNumber: "",
    buildingName: "",
  } as ContractorVO,
  insureds: [
    {
      firstName: "",
      lastName: "",
      kanaFirstName: "",
      kanaLastName: "",
      jobId: "",
      job: "",
      otherJob: "",
      dateOfBirth: "",
      sex: "",
      age: 0,
      jobName: "",
      category: "",
      yearOfBirth: 0,
      monthOfBirth: 0,
      dayOfBirth: 0,
      otherContract: {
        company: "",
        kind: "",
        insurance1: "",
        insurance2: "",
      } as OtherContractVO,
    } as InsuredVO,
  ] as Array<InsuredVO>,
  notifyInfo: {
    hasOtherContract: false,
    statementsResults: [],
  } as NotifyInfoVO,
  amount: "",
  planIndex: "",
  cardInfo: {} as CardInfoVO,
  memberId: "",
  hasPaymentsType: false,
} as any;

type State = {
  saleId: string;
  planIds: Array<string>;
  coverageType: string;
  contractor: ContractorVO;
  insureds: Array<InsuredVO>;
  notifyInfo: NotifyInfoVO;
  amount: string;
  planIndex: string;
  cardInfo: CardInfoVO;
  memberId: string;
  hasPaymentsType: boolean;
};

type Action =
  | { type: "SET_SALE_ID"; saleId: string; }
  | { type: "SET_PLAN_ID"; planIds: Array<string>; }
  | { type: "SET_COVERAGE_TYPE"; coverageType: string; }
  | { type: "SET_CONTRACTOR"; contractor: ContractorVO; }
  | { type: "SET_INSURED"; insureds: Array<InsuredVO>; }
  | { type: "SET_NOTIFY_INFO"; notifyInfo: NotifyInfoVO; }
  | { type: "SET_AMOUNT"; amonut: string; }
  | { type: "SET_PLAN_INDEX"; planIndex: string; }
  | { type: "SET_CARD_INFO"; cardInfo: CardInfoVO; }
  | { type: "SET_MEMBER_ID"; memberId: string; }
  | { type: "SET_CREDIT_FLAG"; hasPaymentsType: boolean; };

const StateContext = createContext<State | null>(null);
const DispatchContext = createContext<Dispatch<Action> | null>(null);

/**
 * ステータス修正メソッド
 *
 * @param state
 * @param action
 * @returns
 */
const _reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_SALE_ID":
      return {
        ...state,
        saleId: action.saleId,
      };
    case "SET_PLAN_ID":
      return {
        ...state,
        planIds: action.planIds,
      };
    case "SET_COVERAGE_TYPE":
      return {
        ...state,
        coverageType: action.coverageType,
      };
    case "SET_CONTRACTOR":
      return {
        ...state,
        contractor: action.contractor,
      };
    case "SET_INSURED":
      return {
        ...state,
        insureds: action.insureds,
      };
    case "SET_NOTIFY_INFO":
      return {
        ...state,
        notifyInfo: action.notifyInfo,
      };
    case "SET_AMOUNT":
      return {
        ...state,
        amount: action.amonut,
      };
    case "SET_PLAN_INDEX":
      return {
        ...state,
        planIndex: action.planIndex,
      };
    case "SET_CARD_INFO":
      return {
        ...state,
        cardInfo: action.cardInfo
      };
    case "SET_MEMBER_ID":
      return {
        ...state,
        memberId: action.memberId
      };
    case "SET_CREDIT_FLAG":
      return {
        ...state,
        hasPaymentsType: action.hasPaymentsType
      };
    default:
      throw new Error("Unhandled action");
  }
};

/**
 * ステータス返還メソッド
 *
 * @returns
 */
const useContextState = () => {
  const state = useContext(StateContext);
  if (!state) {
    throw new Error("Can not find Provider");
  }
  return state;
};

/**
 * ステータス修正メソッド返還
 *
 * @returns
 */
const useContextDispatch = () => {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error("Can not find Provider");
  }
  return dispatch;
};

/**
 * コンテキストプロバイダセッティングメソッド
 *
 * @param children 子コンポーネント
 * @returns
 */
const Provider = (props: { children: any; }) => {
  const [state, dispatch] = useReducer(_reducer, defaultData);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export { Provider, useContextState, useContextDispatch };
