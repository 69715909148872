const ApiOptions = {
  getSales: {
    method: "GET",
    url: "sales",
  },
  getJobList: {
    method: "GET",
    url: "sales/jobs",
  },
  getCustomer: {
    method: "GET",
    url: "sales/customer",
  },
  getProduct: {
    method: "GET",
    url: "sales/product",
  },
  getPremiums: {
    method: "GET",
    url: "sales/premiums",
  },
  application: {
    method: "POST",
    url: "entries",
  },
  getAddressByZipcode: {
    url: "https://zipcloud.ibsnet.co.jp/api/search",
    method: "GET",
    isOpenApi: true,
  },
  getAge: {
    method: "GET",
    url: "sales/age",
  },
  getScreens: {
    method: "GET",
    url: "sales/screens",
  },
  getMemberId: {
    method: "POST",
    url: "cards"
  }
};
export default ApiOptions;
