import { Link } from "react-router-dom";
import PageInfo from "../../common/constant/PageInfo";
/**
 * 職業告知NG
 *
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-06
 * @updatedAt : 2021-12-17
 */
const OccupationCancel = (props: any) => {
  const { currentPage } = props;
  const saleId = window.location.search.replace("?saleId=", "");

  return (
    <article>
      <section className="main-container">
        <h2 className="font-color-blue">お手続きを中止します</h2>
        {currentPage === PageInfo.path.application.preConfirm && (
          <div className="round-area-smooth container-common-border-smooth">
            この保険に加入いただける方は、契約者である団体の役員・従業員・職員などの方です。それ以外の方はこの保険にご加入いただけません。
            <br />
            申し訳ございません。
            <br />
            お手続きを終了する方は、このままブラウザ画面を閉じてください。
          </div>
        )}
        {currentPage === PageInfo.path.occupation.input && (
          <div className="round-area-smooth container-common-border-smooth">
            申込みされる方のご職業は「ご加入いただけないご職業」に該当するため、この保険にご加入いただけません。申し訳ございません。
            <br />
            <br />
            お手続きを終了する方は、このままブラウザ画面を閉じてください。
          </div>
        )}
        <div className="text-align-center-mobile">
          <Link
            to={currentPage + saleId}
            className="font-color-black"
          >
            前の画面に戻る
          </Link>
        </div>
      </section>
    </article>
  );
};

export default OccupationCancel;
