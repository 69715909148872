import Modal from "react-modal";

const customStyles = {
  content: {
    borderRadius: "12px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90%",
    maxWidth: "640px"
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
};
/**
 * 共通Modalコンポーネント
 *
 * @param children 子コンポーネント
 * @param isOpen Modal 実行ステータス
 * @param setIsOpen Modal 制御関数
 * @returns
 */
const BaseModal = (props: {
  children: any;
  isOpen: boolean;
  setIsOpen: Function;
}) => {
  const { children, isOpen, setIsOpen } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="modal-content">{children && children}</div>
      </Modal>
    </div>
  );
};

export default BaseModal;
