let passedPageList: Array<string> = [];

/**
 * 完了した画面管理ユーティール
 *
 * @createdAt : 2021-12-09
 * @updatedAt : 2021-12-17
 */
const PassedPageManagementUtil = {
  /**
   * 現在ページの完了可否を確認
   *
   * @param currentPagePath 現在ページ経路
   * @returns 現在ページ完了結果
   */
  isPassedPage: (currentPagePath: string) => {
    return passedPageList.includes(currentPagePath);
  },

  /**
   * 完了ページセッティング
   *
   * @param currentPagePath
   */
  setPassedPageList: (currentPagePath: string) => {
    if (!PassedPageManagementUtil.isPassedPage(currentPagePath)) {
      passedPageList.push(currentPagePath);
    }
  },

  /**
   * 全て未完了か確認
   *
   * @returns
   */
  isEmptypPassedPageList: () => {
    return passedPageList.length === 0;
  },
};

export default PassedPageManagementUtil;
