import { getErrorContext } from "../context/errorHandlingContext";
import { getTokenContext } from "../context/gmoHandlingContext";

declare global {
  interface Window {
    Multipayment: {
      init: (apiKey?: string) => void;
      getToken: (
        params: ICardTokenParams,
        callback: IMultiPaymentCallback
      ) => void;
    };
    MultipaymentCallback: IMultiPaymentCallback;
  }
}
GmoInit();

export function GmoInit() {
  if (window.Multipayment) {
    window.Multipayment.init(process.env.REACT_APP_GMO_SHOP_ID);
  }
}

export interface ICardTokenParams {
  cardno: string;
  expire: string;
  securitycode: string;
  holdername: string;
}

export interface ITokenObject {
  token: string | string[];
  toBeExpiredAt: string;
  maskedCardNo: string;
  isSecurityCodeSet: boolean;
}

export interface ICardTokenResponse {
  resultCode: string;
  tokenObject: ITokenObject;
}

export interface IMultiPaymentCallback {
  (res: ICardTokenResponse): void;
}

export function MultipaymentCallback(res: ICardTokenResponse) {
  const {
    resultCode,
    tokenObject: { token },
  } = res;
  const tokenContext = getTokenContext();
  const errorContext = getErrorContext();

  if (tokenContext.token === "error") {
    tokenContext.token = "";
    tokenContext.setToken(tokenContext.token);
  }

  if (resultCode !== "000") {
    errorContext.code = resultCode;
    errorContext.message =
      "エラーが発生しました、時間をおいて再度お試しください。";
    errorContext.isCommonModalOpened = true;
    errorContext.setCommonModalOpened(errorContext.isCommonModalOpened);
    tokenContext.token = "error";
    tokenContext.setToken(tokenContext.token);
  } else {
    tokenContext.token = token;
    tokenContext.setToken(tokenContext.token);
  }
}
