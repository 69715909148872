import ApiOptions from "../constant/ApiOptions";
import ApiService from "./ApiService";
import { CustomerVO, ContractVO } from "../../component/model/CustomerModel";
import { SalesVO, AgeRequestVO } from "../../component/model/SalesModel";
import { ProductVO } from "../../component/model/ProductModel";
import { JobListVO } from "../../component/model/JobsModel";
import {
  PremiumsVO,
  PremiumsRequestVO,
} from "../../component/model/PreminumsModel";
import { ScreensVO, ScreenVO } from "../../component/model/ScreensModel";
import { ApplicationRequestVO } from "../../component/model/ApplicationModel";
import { getErrorContext } from "../context/errorHandlingContext";

/**
 * 統合apiサービス
 * @createdAt : 2021-12-03
 * @updatedAt : 2022-03-16
 */

// 取得済みデータ保存変数

let sales: SalesVO = {
  id: "",
  name: "",
  tenantId: "",
  productId: "",
  customerId: "",
  disclosureStatementUrl: "",
  startAt: "",
  endAt: "",
  securityStartAt: "",
  securityEndAt: "",
  birthFrom: "",
  birthTo: "",
  underwriter: "",
  payments: [],
};

let product: ProductVO = {
  id: "",
  name: "",
  kind: "",
  coverageTypes: [],
  statements: [],
  plans: [],
  rate: "",
};

let jobList: JobListVO = {
  jobs: [],
};

let customer: CustomerVO = {
  id: "",
  name: "",
  contact: {} as ContractVO,
};

let screens: ScreensVO = {
  screen: {
    productId: "",
    employeeLabel: "",
    requireOffice: false,
  } as ScreenVO,
};

/**
 * ローカルApiメソッド
 *
 * @param apiOption
 * @param params
 * @returns
 */
const _apiService = async (apiOption: any, params: any) => {
  const { data, error } = await ApiService.request(apiOption, params);

  const errorContext = getErrorContext();

  if (error) {
    if (error.message === "Network Error") {
      window.location.replace("/error");
    }

    errorContext.code = error.response.data.code;
    errorContext.message = error.response.data.message;
    errorContext.isCommonModalOpened = true;
    errorContext.setCommonModalOpened(errorContext.isCommonModalOpened);
    return error;
  }

  return data;
};

const _getSales = async (params: any) => {
  if (!sales.id) {
    sales = await _apiService(ApiOptions.getSales, params);
  }
  return sales;
};
const _getJobList = async (params: any) => {
  if (jobList.jobs.length === 0) {
    jobList = await _apiService(ApiOptions.getJobList, params);
  }
  return jobList;
};
const _getCustomer = async (params: any) => {
  if (!customer.id) {
    customer = await _apiService(ApiOptions.getCustomer, params);
  }

  return customer;
};
const _getProduct = async (params: any) => {
  if (!product.id) {
    product = await _apiService(ApiOptions.getProduct, params);
  }
  return product;
};

const _getPremiums = async (params: PremiumsRequestVO) => {
  return await _apiService(ApiOptions.getPremiums, params);
};

const _getScreens = async (params: any) => {
  if (!screens.screen.productId) {
    screens = await _apiService(ApiOptions.getScreens, params);
  }

  return screens;
};

/**
 * ヘッダー画面とフッター画面で使用するデータの返却
 *
 * @param saleId
 * @returns
 */
const getCustomerForHeaderFooter = async (saleId: string) => {
  const customer = (await _getCustomer({ saleId })) as CustomerVO;
  return {
    customerName: customer.name,
    agency: customer.contact?.agency,
    phone: customer.contact?.phone,
    email: customer.contact?.mail,
  };
};

/**
 * ヘッダー画面で使用するデータの返却
 *
 * @param saleId
 * @returns
 */
const getProductForHeader = async (saleId: string) => {
  const product = (await _getProduct({ saleId })) as ProductVO;
  return product.name;
};

/**
 * ヘッダー画面で使用するデータの返却
 *
 * @param saleId
 * @returns
 */
const getSalesForHeader = async (saleId: string) => {
  const sales = (await _getSales({ saleId })) as SalesVO;
  return {
    securityStartAt: sales.securityStartAt,
    endAt: sales.endAt,
  };
};

/**
 * 加入前確認画面で使用するデータを返却
 *
 * @param saleId
 * @returns
 */
const getDataForApplicationPreConfirm = async (saleId: string) => {
  const sales = (await _getSales({ saleId })) as SalesVO;
  const customer = (await _getCustomer({ saleId })) as CustomerVO;

  return {
    securityStartAt: sales.securityStartAt,
    securityEndAt: sales.securityEndAt,
    startAt: sales.startAt,
    endAt: sales.endAt,
    underwriter: sales.underwriter,
    customerName: customer.name,
    payments: sales.payments,
  };
};

/**
 * 職業入力画面で使用するデータの返却
 *
 * @param saleId
 * @returns
 */
const getDataForOccupationInput = async (saleId: string) => {
  const jobs = (await _getJobList({ saleId })) as JobListVO;
  const sales = (await _getSales({ saleId })) as SalesVO;

  return {
    jobs: jobs.jobs,
    securityStartAt: sales.securityStartAt,
    birthFrom: sales.birthFrom,
    birthTo: sales.birthTo,
  };
};

/**
 * 職業入力画面で使用するデータの返却
 *
 * @param saleId
 * @returns
 */
const getDataForOccupationPlan = async (saleId: string) => {
  const product = (await _getProduct({ saleId })) as ProductVO;

  return {
    coverageTypes: product.coverageTypes,
    plans: product.plans,
    rate: product.rate,
  };
};

/**
 * 加入情報入力画面で使用するデータの返還
 *
 * @param saleId
 * @returns
 */
const getDataForApplcationInput = async (saleId: string) => {
  const screens = (await _getScreens({ saleId })) as ScreensVO;

  return {
    employeeLabel: screens.screen.employeeLabel,
    requireOffice: screens.screen.requireOffice,
  };
};

/**
 * 告知画面で使用するデータの返却
 *
 * @param saleId
 * @returns
 */
const getDataForNotify = async (saleId: string) => {
  const product = (await _getProduct({ saleId })) as ProductVO;
  const sales = (await _getSales({ saleId })) as SalesVO;

  return {
    statements: product.statements,
    payments: sales.payments,
  };
};

/**
 * 入力内容確認画面で使用するデータの返却
 *
 * @param saleId
 * @returns
 */
const getDataForApplicatonConfirm = async (saleId: string) => {
  const product = (await _getProduct({ saleId })) as ProductVO;
  const customer = (await _getCustomer({ saleId })) as CustomerVO;
  const sales = (await _getSales({ saleId })) as SalesVO;
  const screens = (await _getScreens({ saleId })) as ScreensVO;

  return {
    plans: product.plans,
    statements: product.statements,
    customerName: customer.name,
    securityStartAt: sales.securityStartAt,
    securityEndAt: sales.securityEndAt,
    underwriter: sales.underwriter,
    disclosureStatementUrl: sales.disclosureStatementUrl,
    employeeLabel: screens.screen.employeeLabel,
    requireOffice: screens.screen.requireOffice,
    rate: product.rate,
    payments: sales.payments,
  };
};

/**
 * 保険価格の取得
 *
 * @param params
 * @returns
 */
const getPremiumAmount = async (params: PremiumsRequestVO) => {
  const premium = ((await _getPremiums(params)) as PremiumsVO)?.premiums;

  return premium;
};

/**
 * アドレス情報取得
 *
 * @param zipcode
 * @returns
 */
const getAddressByZipcode = async (zipcode: string) => {
  const result = await _apiService(ApiOptions.getAddressByZipcode, {
    zipcode,
  });

  return {
    result,
  };
};

/**
 * 年齢取得
 *
 * @param params
 * @returns
 */
const getAgeByBaseDate = async (params: AgeRequestVO) => {
  const result = await _apiService(ApiOptions.getAge, params);
  return result.age;
};

/**
 * メンバーID取得
 *
 * @param params
 * @returns
 */
const getMemberId = async (params: string | string[]) => {
  const { data, error } = await ApiService.request(ApiOptions.getMemberId, {
    token: params,
  });

  const errorContext = getErrorContext();

  if (error) {
    if (error.message === "Network Error") {
      window.location.replace("/error");
    }

    if (error.response.data.code === "E001003") {
      return { data: undefined, error };
    }

    errorContext.code = error.response.data.code;
    errorContext.message = error.response.data.message;
    errorContext.isCommonModalOpened = true;
    errorContext.setCommonModalOpened(errorContext.isCommonModalOpened);
    return { data: undefined, error: undefined };
  }

  return { data, error: undefined };
};

/**
 * 申込受付
 *
 * @param params
 * @returns
 */
const application = async (params: ApplicationRequestVO) => {
  const { data, error } = await ApiService.request(
    ApiOptions.application,
    params
  );

  const errorContext = getErrorContext();

  if (error) {
    if (error.message === "Network Error") {
      window.location.replace("/error");
    }

    if (error.response.data.code === "E001003") {
      return { data: undefined, error };
    }

    errorContext.code = error.response.data.code;
    errorContext.message = error.response.data.message;
    errorContext.isCommonModalOpened = true;
    errorContext.setCommonModalOpened(errorContext.isCommonModalOpened);
    return { data: undefined, error: undefined };
  }
  return { data, error: undefined };
};

export {
  getDataForApplicationPreConfirm,
  getDataForOccupationInput,
  getDataForOccupationPlan,
  getDataForApplcationInput,
  getDataForNotify,
  getDataForApplicatonConfirm,
  getPremiumAmount,
  application,
  getCustomerForHeaderFooter,
  getProductForHeader,
  getSalesForHeader,
  getAddressByZipcode,
  getAgeByBaseDate,
  getMemberId,
};
