import { createContext } from "react";

export interface errorVo {
  code: string;
  message: string;
  isCommonModalOpened: boolean;
  setCommonModalOpened: Function;
}

const errObj: errorVo = {
  code: "",
  message: "",
  isCommonModalOpened: false,
  setCommonModalOpened: () => { }
};

createContext(errObj);

export const getErrorContext = () => {
  return errObj;
}