import { useState } from "react";
import ValidationUtil from "../../../common/util/ValidationUtil";
import { ValidationVO } from "../../model/CommonModel";

type inputType = "text" | "number" | "password";

/**
 * 共通入力コンポーネント
 *
 * @param props
 * @returns
 */
const BaseInputText = (props: {
  id?: string;
  name?: string;
  type?: inputType;
  value: any;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  className?: string;
  validationOptions?: ValidationVO;
  isStartValidation: boolean;
  disableEmptyValueCheck?: boolean;
}) => {
  const [message, setMessage] = useState<string>();
  const {
    id,
    name,
    type,
    value,
    onChange,
    onBlur,
    disabled,
    maxLength,
    className,
    validationOptions,
    isStartValidation,
    placeholder,
    disableEmptyValueCheck,
  } = props;

  const autoType = type === "password" ? "new-password" : "off";

  /**
   * 有効性検査内容を画面に表示
   *
   */
  const onBlurValdation = (e: any) => {
    setMessage("");

    if (onBlur) {
      onBlur(e);
    }

    const { value } = e.target;

    if (!validationOptions) {
      return;
    }

    if (!disableEmptyValueCheck && !value) {
      setMessage("入力してください");
      return;
    }

    if (value) {
      const errorMessage = ValidationUtil.validationByOption(
        value,
        validationOptions
      );

      setMessage(errorMessage ?? "");
    }
  };

  return (
    <div className="base-input-area">
      <input
        id={id}
        name={name}
        className={className ?? "base-input-text"}
        type={type}
        value={value ?? ""}
        onChange={onChange}
        disabled={disabled}
        maxLength={maxLength}
        onBlur={onBlurValdation}
        autoComplete={autoType}
        placeholder={
          placeholder ? placeholder : isStartValidation ? "ご入力ください" : ""
        }
      />
      {message && <div>{message}</div>}
    </div>
  );
};
export default BaseInputText;
