import { ReactNode, useEffect, useState, useRef } from "react";
import {
  useContextDispatch,
  useContextState,
} from "../../common/service/Context";
import ValidationUtil from "../../common/util/ValidationUtil";
import PageInfo from "../../common/constant/PageInfo";
import { ValidationVO } from "../model/CommonModel";
import { Link } from "react-router-dom";
import BaseInputText from "../ui/base/BaseInputText";
import Navigator from "../common/Navigator";
import { CardInfoVO } from "../model/SalesModel";
import BaseDropdown from "../ui/base/BaseDropdown";
import { GmoInit, MultipaymentCallback } from "../../common/service/Gmo";
import { getTokenContext } from "../../common/context/gmoHandlingContext";
import PassedPageManagementUtil from "../../common/util/PassedPageManagementUtil";
import { getMemberId } from "../../common/service/AipomApiService";
import DateUtil from "../../common/util/DateUtil";
import BaseModal from "../ui/base/BaseModal";
import BaseLoader from "../ui/base/BaseLoader";

const REQUIRE_LIST = [
  "cardno",
  "holdername",
  "yearOfExpire",
  "monthOfExpire",
  "securitycode",
];
const IMAGES_SRC_LIST = [
  "/resources/images/card/Visa_Brandmark_Blue_RGB_2021.png",
  "/resources/images/card/mc_symbol_opt_73_3x.png",
  "/resources/images/card/JCB(B-3).gif",
  "/resources/images/card/AXP_1C_solid.jpg",
  "/resources/images/card/Diners_Logo_for_Merchant.jpg",
];

const VALIDATION_OPTIONS = {
  cardno: { minLength: 10, maxLength: 16, format: "number" },
  holdername: { maxLength: 51, format: "cardName" },
  securitycode: { minLength: 3, maxLength: 4, format: "number" },
} as any;

const defaultData = {
  cardInfo: {
    cardno: "",
    holdername: "",
    yearOfExpire: "",
    monthOfExpire: "",
    securitycode: "",
  } as CardInfoVO
};
window.MultipaymentCallback = MultipaymentCallback;

/**
 * お支払い方法
 *
 * @author : SANGMIN LEE
 * @createdAt : 2022-06-23
 * @updatedAt : 2022-06-24
 */
const PaymentInput = (props: any) => {
  const [formData, setFormData] = useState<CardInfoVO>(defaultData.cardInfo);
  const [isStartValidation, setIsStartValidation] = useState<boolean>(false);
  const [isOpenCustomErrorModal, setIsOpenCustomErrorModal] = useState<boolean>(false);
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [canGoNext, setCanGoNext] = useState<boolean>(false);
  const tokenKey = getTokenContext();
  const state = useContextState();
  const dispatch = useContextDispatch();
  const isModifyMode = useRef<boolean>(false);
  tokenKey.setToken = () => {
    const cardInfo = { ...formData };
    cardInfo.tokennumber = tokenKey.token;
    setFormData(cardInfo);
  };

  useEffect(() => {
    goNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenKey.token]);

  useEffect(() => {
    if (props.currentPage !== PageInfo.path.application.payment) {
      window.scrollTo(0, 0);
      props.setCurrentPage(PageInfo.path.application.payment);
    }

    (PassedPageManagementUtil.isPassedPage(PageInfo.path.application.payment) || state.cardInfo.holdername) && setState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickNextButton = () => {
    if (!isValidData()) {
      setIsStartValidation(true);
      setIsInvalidData(true);
      return;
    }
    getTokenKey();
    setIsPending(true);
    setCanGoNext(true);
  };

  const goNext = async () => {
    if (!canGoNext || tokenKey.token === "error" || !tokenKey.token) {
      setIsPending(false);
      return;
    }
    setIsPending(true);
    const { data, error } = await getMemberId(tokenKey.token);

    setIsPending(false);
    if (error) {
      setIsOpenCustomErrorModal(true);
      return;
    }
    if (!data?.memberId) {
      return;
    }
    setContext();
    dispatch({ type: "SET_MEMBER_ID", memberId: data.memberId });
    PassedPageManagementUtil.setPassedPageList(PageInfo.path.application.payment);
    props.history.push(PageInfo.path.application.confirm + state.saleId);
  };

  const getTokenKey = () => {
    const params = {
      cardno: formData.cardno,
      expire: formData.yearOfExpire.concat(formData.monthOfExpire.padStart(2, '0')),
      securitycode: formData.securitycode,
      holdername: formData.holdername,
    };
    GmoInit();
    window.Multipayment.getToken(params, window.MultipaymentCallback);
    setIsPending(false);
  };

  const isValidData = () => {
    let result = true;
    if (!ValidationUtil.hasValues(formData, REQUIRE_LIST) || isPending) {
      return false;
    }

    Object.entries(formData).forEach(([key, value]) => {
      const validationOption = VALIDATION_OPTIONS[key] as ValidationVO;

      if (
        validationOption &&
        ValidationUtil.validationByOption(value, validationOption)
      ) {
        result = false;
      }
    });

    return result;

  };

  const setState = () => {
    if (isModifyMode.current) {
      return;
    }

    const stateData = {
      cardno: state.cardInfo.cardno,
      holdername: state.cardInfo.holdername,
      yearOfExpire: state.cardInfo.yearOfExpire,
      monthOfExpire: state.cardInfo.monthOfExpire,
      securitycode: state.cardInfo.securitycode,
    } as any;

    setFormData(stateData);
  };

  const setContext = async () => {
    let tempCardInfo = { ...state.cardInfo };
    tempCardInfo.cardno = formData.cardno;
    tempCardInfo.holdername = formData.holdername;
    tempCardInfo.yearOfExpire = formData.yearOfExpire;
    tempCardInfo.monthOfExpire = formData.monthOfExpire;
    tempCardInfo.securitycode = formData.securitycode;
    tempCardInfo.tokennumber = tokenKey.token;

    dispatch({ type: "SET_CARD_INFO", cardInfo: tempCardInfo });
  };

  const getImages = () => {
    const images: Array<ReactNode> = [];

    IMAGES_SRC_LIST.forEach((src, index) => {
      images.push(<img src={src} className="card-icon" alt={"card_img"} key={"card_" + index} />);
    });

    return images;
  };

  return (
    <article>
      <section className="main-container mgt24 mgb24">
        <Navigator currentPage={PageInfo.path.application.payment} />
        <h2 className="font-color-blue font-weight-bold">
          6.お支払い方法の入力
        </h2>
        <div className="mgb12">
          <h4 className="content-board-title font-weight-bold font-color-darkbrown">
            加入される方のクレジットカード情報を入力してください
          </h4>
        </div>
        <p className="warning">
          <span>
            利用可能なクレジットカード:
          </span>
        </p>
        <div className="card-icon-container">
          {getImages()}
        </div>
        <div className="container-common-border border-line-blue">
          <div className="">
            <label className="font-weight-bold font-size-18">
              カード番号
            </label>
            <label className="required-label">必須</label>
            <p className="warning">
              <span>
                お支払いは、ご加入者本人名義のクレジットカードでのみ承ります。
                <br />
                加入者名 {`${state.contractor.lastName} ${state.contractor.firstName}`}
              </span>
            </p>
            <BaseInputText
              value={formData.cardno}
              onChange={(e) => {
                const { value } = e.target;
                setFormData({ ...formData, cardno: value });
              }}
              placeholder={"カード番号"}
              isStartValidation={isStartValidation}
              validationOptions={VALIDATION_OPTIONS.cardno}
              className={"base-input-text-width-full"}
            />
            <p className="warning">
              <span>
                ハイフンなしでご入力ください。
              </span>
            </p>
          </div>
          <div className="mgt24">
            <label className="font-weight-bold font-size-18">
              クレジットカード名義人
            </label>
            <label className="required-label">必須</label>
            <BaseInputText
              value={formData.holdername}
              onChange={(e) => {
                const { value } = e.target;
                setFormData({ ...formData, holdername: value });
              }}
              placeholder={"カード名義人"}
              isStartValidation={isStartValidation}
              validationOptions={VALIDATION_OPTIONS.holdername}
              className={"base-input-text-width-full"}
            />
            <p className="warning">
              <span>
                カード表記のままでご入力ください。
              </span>
            </p>
          </div>
          <div className="mgt24">
            <label className="font-weight-bold font-size-18">
              カード有効期限（月/年）
            </label>
            <label className="required-label">必須</label>
            <div className="card-expire-container">
              <div className="mgb12 mgt12 drop-down-card month">
                <BaseDropdown
                  placeholder={"月"}
                  codeList={DateUtil.monthCodeList(true)}
                  value={formData.monthOfExpire}
                  onChange={(e) => {
                    setFormData({ ...formData, monthOfExpire: e.target.value });
                  }}
                  isStartValidation={isStartValidation}
                />
              </div>
              <div className="mgr20 mgl20 mgt24 font-size-22">
                /
              </div>
              <div className="mgb12 mgt12 drop-down-card">
                <BaseDropdown
                  placeholder={"年"}
                  codeList={DateUtil.yearCodeList(new Date().getFullYear(), new Date().getFullYear() + 20)}
                  value={formData.yearOfExpire}
                  onChange={(e) => {
                    setFormData({ ...formData, yearOfExpire: e.target.value });
                  }}
                  isStartValidation={isStartValidation}
                />
              </div>
            </div>
            <p className="warning">
              <span>
                カード表記のままでご入力ください。
              </span>
            </p>
          </div>
          <div className="mgt24">
            <label className="font-weight-bold font-size-18">
              セキュリティーコード
            </label>
            <label className="required-label">必須</label>
            <BaseInputText
              value={formData.securitycode}
              type="password"
              onChange={(e) => {
                const { value } = e.target;
                setFormData({ ...formData, securitycode: value });
              }}
              placeholder={"コード "}
              isStartValidation={isStartValidation}
              validationOptions={VALIDATION_OPTIONS.securitycode}
            />
            <p className="warning">
              <span>
                カード裏面の署名欄の3桁または4桁の数字（7桁の場合下3桁）をご入力ください。
                <br />
                American Expressの場合はカード表面右上または左上の4桁の数字です。
              </span>
            </p>
          </div>
        </div>

        <div className="warning mgt12">
          <span>※</span>
          <span>クレジットカード決済処理に時間がかかることがあります。</span>
        </div>
        <div className="warning">
          <span>※</span>
          <span>海外発行のクレジットカードはご利用いただけない場合があります。</span>
        </div>
        <div className="warning">
          <span>※</span>
          <span>古いOSやブラウザからではクレジットカード決済が行えない場合があります。</span>
        </div>

        <div className="board-button-two">
          <div>
            <button
              className={isPending ? "button-confirm-orange-disable" : "button-confirm-orange"}
              onClick={onClickNextButton}
            >
              <div>
                <span></span>
                <span>次へ</span>
                <span className="button-arrow"></span>
              </div>
            </button>
          </div>
          <div className="board-link">
            <Link
              to={PageInfo.path.application.notify + state.saleId}
            >
              戻る
            </Link>
          </div>
        </div>
      </section>
      <section>
        <BaseModal isOpen={isInvalidData} setIsOpen={setIsInvalidData}>
          <h4>入力項目を確認してください。</h4>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsInvalidData(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseModal isOpen={isOpenCustomErrorModal} setIsOpen={setIsOpenCustomErrorModal}>
          <div className="font-weight-bold font-size-18">
            カード登録の処理に失敗しました。
            <br />
            入力された内容をご確認ください。
          </div>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsOpenCustomErrorModal(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseLoader isOpen={isPending}>
          <p className="font-weight-bold font-size-18">確認中です</p>
        </BaseLoader>
      </section>

    </article>
  );
};

export default PaymentInput;
