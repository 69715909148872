import BaseModal from "./BaseModal";
import PulseLoader from "react-spinners/PulseLoader";

const BaseLoader = (props: { children: any; isOpen: boolean; }) => {
  return (
    <BaseModal isOpen={props.isOpen} setIsOpen={() => { }}>
      <div className="loader-container">
        <PulseLoader color="#05BA82" size={16} />
      </div>
      {props.children}
    </BaseModal>
  );

};

export default BaseLoader;