type PropsType = {
  isShowingOption: boolean;
  optionId?: string;
  isCheckedOption: boolean;
  onChange?: any;
};

const PlanBoard = ({
  optionId,
  isShowingOption,
  isCheckedOption,
  onChange
}: PropsType) => {
  return (
    <>
      <label className="font-weight-bold font-color-navy">
        補償内容
      </label>
      <hr />
      <div className="plan-board-detail-price">
        <div>
          <span className="font-size-14">
            死亡保険金額
          </span>
        </div>
        <div className="font-size-14 font-color-blue">
          <span>200万</span>
          <span>円</span>
        </div>
      </div>
      <div className="plan-board-detail-price">
        <div>
          <span className="font-size-14">
            後遺障害保険金額
          </span>
        </div>
        <div className="font-size-14 font-color-blue">
          <span>障害の程度に応じて </span>
          <br />
          <span>8万円~200万</span>
          <span>円</span>
        </div>
      </div>
      <div className="plan-board-detail-price">
        <div>
          <span className="font-size-14">
            入院保険金日額
          </span>
          <div className="font-size-12">
            （1事故につき180日限度）
          </div>
        </div>
        <div className="font-size-14 font-color-blue">
          <span>2,000</span>
          <span>円</span>
        </div>
      </div>
      <div className="plan-board-detail-price">
        <div>
          <span className="font-size-14">
            手術保険金額
          </span>
          <div className="font-size-12">
            （1事故につき1回）
          </div>
        </div>
        <div>
          <div className="font-size-14 font-color-blue">
            <span>入院中 </span>
            <span>20,000</span>
            <span>円</span>
          </div>
          <div className="font-size-14 font-color-blue">
            <span>入院中以外 </span>
            <span>10,000</span>
            <span>円</span>
          </div>
        </div>
      </div>

      <br />
      <label className="font-size-12 font-color-navy">
        地震・噴火・津波危険補償特約、特定感染症危険「後遺障害保険金、入院保険金および通院保険金」支払特約セット
      </label>
      <div className="plan-board-detail-price">
        <div>
          <span className="font-size-14">
            個人賠償責任補償（1事故あたりの限度額）
          </span>
        </div>
        <div className="font-size-14 font-color-blue">
          <span>3億</span>
          <span>円</span>
        </div>
      </div>
      <div className="plan-board-detail-price">
        <div>
          <span className="font-size-14">
            携行品損害補償（保険期間中の限度額）
          </span>
          <div className="font-size-12">
            （自己負担額：1事故につき3,000円）
          </div>
        </div>
        <div className="font-size-14 font-color-blue">
          <span>10万</span>
          <span>円</span>
        </div>
      </div>
      {isShowingOption &&
        <div className="plan-board-option mgt24">
          <div>さらにアップグレードプランも、ご用意!</div>
          <div>
            <label className="font-size-small">
              <div className="font-size-16">
                ベースプランに、通院に対する保険金もセットしたアップグレードプラン。アウトドア派のあなたへおすすめ!
              </div>

              <div className="plan-option-price mgt24">
                <label className="plan-option-check-box" htmlFor={optionId}>
                  {!isCheckedOption ? (
                    <>
                      <div className="icon-speech-bubble">
                        <span>選択する</span>
                        <div className="icon-speech-bubble-triangle"></div>
                      </div>
                      <div className="icon-check"></div>
                    </>
                  ) : (
                    <>
                      <div className="icon-speech-bubble-checked">
                        <span>選択中</span>
                        <div className="icon-speech-bubble-triangle-checked"></div>
                      </div>
                      <div className="icon-check-checked"></div>
                    </>
                  )}
                </label>

                {optionId && onChange &&
                  <input
                    className="visibility-collapse display-none"
                    id={optionId}
                    type="checkbox"
                    checked={isCheckedOption}
                    onChange={onChange}
                  />
                }
                <div className="plan-option-price">
                  <span className="font-size-16">
                    通院保険金日額
                    <div className="font-size-12">
                      （1事故につき30日限度）
                    </div>
                  </span>
                  <span className="font-size-16">1,500円</span>
                </div>
              </div>
              <p className="warning">
                <span>
                  通院保険金支払限度日数短縮特約（30日限度）セット
                  <br />
                  通院保険金日額をセットした場合、地震・噴火・津波危険補償特約、特定感染症危険｢後遺障害保険金、入院保険金および通院保険金｣支払特約についても、通院が補償対象となります。
                </span>
              </p>
            </label>
          </div>
        </div>
      }
    </>
  );
};
export default PlanBoard;