import { useState, useEffect, useRef } from "react";
import {
  useContextState,
  useContextDispatch,
} from "../../common/service/Context";
import { getPremiumAmount } from "../../common/service/AipomApiService";
import PassedPageManagementUtil from "../../common/util/PassedPageManagementUtil";
import ValidationUtil from "../../common/util/ValidationUtil";
import PageInfo from "../../common/constant/PageInfo";
import { PremiumsRequestVO } from "../model/PreminumsModel";
import { PlanVO } from "../model/ProductModel";
import { COVERAGE_TYPES_CODE } from "../../common/constant/CodeForAipom";
import {
  ETC_OF_JOB_DETAIL,
  EMPTY_MESSAGE,
  PENDING_MESSAGE,
} from "../../common/enum/EnumForAipom";
import { Link } from "react-router-dom";
import BaseModal from "../ui/base/BaseModal";
import Navigator from "../common/Navigator";
import PlanBoard from "../ui/PlanBoard";

const REQUIRE_LIST = ["coverageType", "planIds"];

const defaultData = {
  formData: {
    coverageType: "",
    planIds: [],
  },
};

/**
 * 職業入力
 *
 * @author : SANGMIN LEE
 * @createdAt : 2021-12-06
 * @updatedAt : 2021-12-17
 */
const OccupationPlan = (props: any) => {
  const [formData, setFormData] = useState<any>(defaultData.formData);
  const [isRangeOfInsuredModalOpen, setIsRangeOfInsuredModalOpen] =
    useState<boolean>(false);
  const [isPlanInfoModalOpen, setIsPlanInfoModalOpen] =
    useState<boolean>(false);
  const [isAmountInfoModalOpen, setIsAmountInfoModalOpen] =
    useState<boolean>(false);
  const [premiums, setPremiums] = useState<Array<any>>();
  const [isStartValidation, setIsStartValidation] = useState<boolean>(false);
  const [showingContentIndex, setShowingContentIndex] = useState<number>();
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);
  const [basePlans, setBasePlans] = useState<Array<PlanVO>>([]);
  const [planOptions, setPlanOptions] = useState<Array<PlanVO>>([]);
  const [isShowingPlanDetail, setIsShowingPlanDetail] = useState<boolean>(true);
  const isModifyMode = useRef<boolean>(false);
  const isPending = useRef<boolean>(false);
  const { coverageTypes, plans, rate } = props.data;
  const state = useContextState();
  const dispatch = useContextDispatch();

  useEffect(() => {
    if (props.currentPage !== PageInfo.path.occupation.plan) {
      window.scrollTo(0, 0);
      props.setCurrentPage(PageInfo.path.occupation.plan);
    }
    setPlans();

    PassedPageManagementUtil.isPassedPage(PageInfo.path.occupation.plan) &&
      setState();

    getPremiums();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverageTypes, plans, formData]);

  /**
   * 加入前確認で画面遷移
   * @returns
   */
  const onClickNextButton = () => {
    if (
      !ValidationUtil.hasValues(formData, REQUIRE_LIST) ||
      (formData.job === ETC_OF_JOB_DETAIL &&
        (!ValidationUtil.hasValue(formData.otherJob) ||
          !ValidationUtil.isValidFromMaxLength(formData.otherJob, 20))) ||
      isPending.current
    ) {
      setIsStartValidation(true);
      setIsInvalidData(true);
      return;
    }

    setContext();
    PassedPageManagementUtil.setPassedPageList(PageInfo.path.occupation.plan);
    props.history.push(
      PageInfo.path.application.input + state.saleId
    );
  };

  /**
   * プランオプション選択イベント
   * @param e
   */
  const onChangePlanOption = (e: any) => {
    const { checked, id } = e.target;

    const planIds = [...(formData.planIds ?? [])];

    if (checked) {
      planIds.push(id);
      setFormData({ ...formData, planIds: planIds });
    } else {
      const tempPlanIds = planIds.filter((plan) => id !== plan);
      setFormData({ ...formData, planIds: tempPlanIds });
    }
  };

  /**
   * 修正時データセッティング
   */
  const setState = () => {
    if (isModifyMode.current) {
      return;
    }

    const stateData = { ...formData };
    stateData.coverageType = state.coverageType;
    stateData.planIds = state.planIds;

    isModifyMode.current = true;
    setShowingContentIndex(Number.parseInt(state.planIndex));
    setFormData(stateData);
  };

  /**
   * データ全域ステートに保存
   */
  const setContext = () => {
    const amount = getAmountValue(formData.planIds[0]);

    dispatch({ type: "SET_SALE_ID", saleId: state.saleId });
    dispatch({ type: "SET_PLAN_ID", planIds: formData.planIds });
    dispatch({
      type: "SET_COVERAGE_TYPE",
      coverageType: formData.coverageType,
    });
    dispatch({ type: "SET_AMOUNT", amonut: amount + "" });
    dispatch({ type: "SET_PLAN_INDEX", planIndex: showingContentIndex + "" });
  };

  /**
   * ベースフランとプランオプションの分離
   *
   * @returns
   */
  const setPlans = () => {
    if (!plans || Object.keys(basePlans).length !== 0) {
      return;
    }
    const tempBasePlans = new Array<PlanVO>();
    const tempPlanOptions = new Array<PlanVO>();

    (plans as Array<PlanVO>).forEach((plan) => {
      if (plan.isBase) {
        tempBasePlans.push(plan);
      } else {
        tempPlanOptions.push(plan);
      }
    });

    const tempPlanIds = formData.planIds;
    if (!(tempPlanIds as Array<any>).includes(tempBasePlans[0].id)) {
      tempPlanIds.push(tempBasePlans[0].id);
    }

    setPlanOptions(tempPlanOptions);
    setBasePlans(tempBasePlans);
    setFormData({ ...formData, planIds: tempPlanIds });
    setShowingContentIndex(0);
  };

  /**
   * プラン価格取得
   *
   * @returns
   */
  const getPremiums = async () => {
    if (!formData.coverageType) {
      return;
    }

    const age = state.insureds[0].age + "";
    const coverageType = formData.coverageType;
    const sex = state.insureds[0].sex;
    const jobCategory = state.insureds[0].category;
    const params = {
      saleId: state.saleId,
      jobCategory,
      coverageType,
      age,
      sex,
    } as PremiumsRequestVO;

    isPending.current = true;

    const result = await getPremiumAmount(params);

    isPending.current = false;
    setPremiums(result);
  };

  /**
   * amount返還
   *
   * @returns amount
   */
  const getAmountValue = (planId: string) => {
    if (!premiums) {
      return PENDING_MESSAGE;
    }

    let amount = 0;
    const planIds = [...(formData.planIds ?? [])];

    if (!planIds.includes(planId)) {
      planIds.push(planId);
    }
    planIds.sort();

    premiums.forEach((premium) => {
      if (premium.planIds.length === planIds.length) {
        premium.planIds.sort();

        planIds.forEach((planId, index) => {
          if (planId === premium.planIds[index]) {
            amount = premium.amount;
          }
        });
      }
    });

    return amount;
  };

  /**
   * 補償範囲名前形式変換
   *
   * @param coverageType
   * @returns
   */
  const formatCoverageTypesName = (coverageType: string) => {
    const splitedText = coverageType.split("（");

    return (
      <span>
        <span className="font-size-18"> {splitedText[0]}</span>
        <br />
        <span className="font-size-14"> {"（" + splitedText[1]}</span>
      </span>
    );
  };

  const onClickSummaryLink = () => {
    window.open("https://www.aig.co.jp/sonpo/lp/ind/newnormal/hosyougaiyou.html");
  };

  return (
    <article>
      <section className="main-container mgt24 mgb24">
        <Navigator currentPage={PageInfo.path.occupation.plan} />
        <h2 className="font-color-blue font-weight-bold">
          3.契約タイプ・加入プラン選択
        </h2>
        <h4 className="content-board-title font-weight-bold font-color-darkbrown">
          契約タイプ（被保険者の範囲）を選択してください
        </h4>
        <label className="link_label mgb24">
          <div></div>
          <label
            onClick={() => {
              setIsRangeOfInsuredModalOpen(!isRangeOfInsuredModalOpen);
            }}
          >
            契約タイプ（被保険者の範囲）とは
          </label>
        </label>
        <div className="content-board-content border-line-blue">
          <div className="display-flex-middle">
            {!coverageTypes && (
              <label className="base-radio-label">
                <div className="radio-button-check">
                  <div></div>
                </div>
                <span className="radio-button-text font-color-darkbrown">
                  {PENDING_MESSAGE}
                </span>
              </label>
            )}
            {COVERAGE_TYPES_CODE.map((code, index) => {
              if (
                coverageTypes &&
                (coverageTypes as Array<string>).includes(code.codeValue)
              ) {
                return (
                  <div className="min-width110" key={"coverage_type_" + index}>
                    <label
                      className={
                        formData.coverageType === code.codeValue
                          ? "coverage-type-label-active"
                          : "coverage-type-label"
                      }
                    >
                      {formData.coverageType === code.codeValue ? (
                        <div className="radio-button-check-active">
                          <div className="radio-button-check-item"></div>
                        </div>
                      ) : (
                        <div className="radio-button-check">
                          <div></div>
                        </div>
                      )}

                      <span
                        className={
                          formData.coverageType === code.codeValue
                            ? "radio-button-text font-color-orange"
                            : "radio-button-text font-color-darkbrown"
                        }
                      >
                        {formatCoverageTypesName(code.codeName)}
                      </span>

                      <input
                        className="visibility-collapse"
                        type="radio"
                        name="coverageTypes"
                        defaultChecked={
                          formData.coverageType === code.codeValue
                        }
                        value={code.codeValue}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            coverageType: e.target.value,
                          });
                        }}
                      />
                    </label>
                  </div>
                );
              } else {
                return <span key={"coverage_type_" + index}></span>;
              }
            })}
          </div>
          {isStartValidation && !formData.coverageType && (
            <div className="base-input-area">
              <div>{EMPTY_MESSAGE}</div>
            </div>
          )}
        </div>
      </section>

      <section className="content-board background-bage">
        <h4 className="content-board-title  mgl8">
          加入プランを選択してください
        </h4>
        <label className="link_label mgt24 mgb24">
          <div></div>
          <label
            onClick={() => {
              setIsAmountInfoModalOpen(!isAmountInfoModalOpen);
            }}
          >
            契約タイプ・加入プランごとの保険料
          </label>
        </label>
        <label className="link_label mgt24 mgb24">
          <div></div>
          <label onClick={onClickSummaryLink}>
            補償概要
          </label>
        </label>

        {!basePlans && (
          <div className="plan-board-info">
            <div>
              <div className="radio-button-check">
                <div></div>
              </div>
              <span className="radio-button-text font-color-darkbrown">
                {PENDING_MESSAGE}
              </span>
            </div>
          </div>
        )}
        <div className="plan-board-cover max-width640">
          {basePlans.map((plan: PlanVO, index: number) => {
            const isSelecting = (formData.planIds as Array<any>)?.includes(
              plan.id
            );

            if (plan.isBase) {
              return (
                <div
                  className={isSelecting ? "plan-board-active" : "plan-board"}
                  key={"plan_" + index}
                >
                  <label id={plan.name}>
                    <input
                      type="radio"
                      name="plan"
                      className="visibility-collapse display-none"
                      defaultChecked={isSelecting}
                      onClick={() => setShowingContentIndex(index)}
                      value={plan.id}
                      onChange={(e) => {
                        const tempPlanIds = [];
                        tempPlanIds.push(e.target.value);

                        setFormData({
                          ...formData,
                          planIds: tempPlanIds,
                        });
                      }}
                    />
                    <div className="plan-board-info">
                      <div>
                        {isSelecting ? (
                          <div className="radio-button-check-active">
                            <div className="radio-button-check-item"></div>
                          </div>
                        ) : (
                          <div className="radio-button-check">
                            <div></div>
                          </div>
                        )}
                        <span
                          className={
                            isSelecting
                              ? "radio-button-text font-color-orange plan-title-info"
                              : "radio-button-text font-color-darkbrown plan-title-info"
                          }
                        >
                          <span
                            className={isSelecting ? "font-weight-bold" : ""}
                          >
                            {plan.name}
                            {isSelecting && (
                              <span className="icon-selecting"></span>
                            )}
                          </span>
                          <span className="font-color-darkbrown font-size-16">
                            団体割引率{rate}適用
                          </span>
                        </span>
                        {!isShowingPlanDetail && (
                          <div className="mgl20">
                            <span
                              className="font-size-14 font-weight-normal font-color-darkbrown"
                              onClick={() =>
                                setIsShowingPlanDetail(!isShowingPlanDetail)
                              }
                            >
                              プランを選択して詳細を見る
                            </span>
                            <div className="icon-reverse-triangle"></div>
                          </div>
                        )}
                      </div>
                    </div>
                    {isShowingPlanDetail && (
                      <div className={isSelecting ? "" : "plan-board-content"}>
                        <div>
                          <div className="plan-board-detail">
                            <PlanBoard
                              isShowingOption={true}
                              isCheckedOption={formData.planIds.includes(planOptions[0].id)}
                              optionId={planOptions[0].id}
                              onChange={onChangePlanOption}
                            />
                            {!formData.coverageType ? (
                              ""
                            ) : premiums && getAmountValue(plan.id) ? (
                              <div className="plan-board-amount">
                                <div className="plan-board-amount-title">
                                  ご選択内容は以下です
                                  <div className="plan-board-amount-triangle"></div>
                                </div>

                                <div className="plan-board-amount-content">
                                  <span className="font-size-14 font-color-navy font-weight-bold">
                                    ご選択プラン
                                  </span>
                                  <span className="font-size-14 font-color-orange font-weight-bold">
                                    {!(
                                      formData.planIds as Array<any>
                                    )?.includes(planOptions[0].id)
                                      ? "ベースプラン"
                                      : "アップグレードプラン"}
                                  </span>
                                </div>
                                <div className="plan-board-amount-content">
                                  <span className="font-size-14 font-color-navy font-weight-bold">
                                    {state.hasPaymentsType
                                      ? "一時払保険料"
                                      : "月払保険料"}
                                  </span>
                                  <span>
                                    <span
                                      className={
                                        isSelecting
                                          ? "font-color-orange font-weight-bold font-size-22"
                                          : ""
                                      }
                                    >
                                      {(
                                        getAmountValue(plan.id) as number
                                      ).toLocaleString("ja-JP")}
                                    </span>
                                    <span className="font-size-14 font-color-darkbrown">
                                      &nbsp;円
                                    </span>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <span className="font-size-20 font-weight-bold font-color-darkbrown">
                                {PENDING_MESSAGE}
                              </span>
                            )}
                            <p className="warning">
                              <span>（注）</span>
                              <span>
                                夫婦型、家族型、本人・親族型の契約タイプを選択された場合、死亡、後遺障害、入院、手術、通院の各保険金額は、被保険者ごとに上記保険金額となります。
                                <br />
                                携行品損害保険金額は、被保険者全員合算で、保険期間を通じてご加入の保険金額が限度額となります。
                                <br />
                                個人賠償責任補償は、１事故につきご加入の保険金額が限度額となります。
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </label>
                </div>
              );
            } else {
              return <div key={"plan_" + index}></div>;
            }
          })}
        </div>
        {isStartValidation && !formData.planIds && (
          <div className="base-input-area">
            <div>{EMPTY_MESSAGE}</div>
          </div>
        )}
      </section>

      <div className="board-button-two">
        <div>
          <button className="button-confirm-orange" onClick={onClickNextButton}>
            <div>
              <span></span>
              <span>次へ</span>
              <span className="button-arrow"></span>
            </div>
          </button>
        </div>
        <div className="board-link">
          <Link to={PageInfo.path.occupation.input + state.saleId}>
            戻る
          </Link>
        </div>
      </div>

      <section>
        <BaseModal
          isOpen={isRangeOfInsuredModalOpen}
          setIsOpen={() => {
            setIsRangeOfInsuredModalOpen(!isRangeOfInsuredModalOpen);
          }}
        >
          <h4 className="no-mgt">
            契約タイプでは、保険の対象となる方（被保険者）の範囲をお選びいただけます。
            <br />
            いずれのタイプも加入者が被保険者ご本人となります。
          </h4>
          <img
            className="insurance-target"
            src="/resources/images/insurance_target.jpg"
            alt="保険の対象"
          ></img>
          <br />
          <p>
            （※1）婚姻の相手方をいいます。なお、婚姻の届出をしていないが事実上婚姻関係と同様の事情にある方、および、戸籍上の性別が同一であるが婚姻関係と異ならない程度の実質を備える状態にある方を含みます（ただし、これらの事情・状態にあることを、書面などにより確認できる場合に限ります。）。
          </p>
          <p>
            （※2）家族型の場合、ご本人またはその配偶者の同居の親族および別居の未婚（※3）の子をいいます。
            <br />
            本人・親族型の場合、ご本人の同居の親族（配偶者を除きます。）および別居の未婚（※3）の子をいいます。
          </p>
          <p>（※3）これまでに婚姻歴のないことをいいます。</p>

          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsRangeOfInsuredModalOpen(!isRangeOfInsuredModalOpen);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseModal
          isOpen={isPlanInfoModalOpen}
          setIsOpen={() => {
            setIsPlanInfoModalOpen(!isPlanInfoModalOpen);
          }}
        >
          <h4 className="no-mgt">
            プランのごとの保障・補債内容は以下の表でご確認ください
          </h4>
          <img
            className="insurance-target"
            src="/resources/images/insurance_target.jpg"
            alt="保険の対象"
          ></img>
          <br />
          <p>各プランの詳細は、画面を横スクロールしてご 覧ください。</p>
          <p>
            (※2)
            本人またはその配偶者の同居の親族および別居の未婚の子をいいます。
          </p>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsPlanInfoModalOpen(!isPlanInfoModalOpen);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseModal isOpen={isInvalidData} setIsOpen={setIsInvalidData}>
          <h4>入力項目を確認してください。</h4>
          <button
            className="button-common-blue mgt36"
            onClick={() => {
              setIsInvalidData(false);
            }}
          >
            閉じる
          </button>
        </BaseModal>
        <BaseModal
          isOpen={isAmountInfoModalOpen}
          setIsOpen={setIsAmountInfoModalOpen}
        >
          <div>
            <h4>
              契約タイプ・加入プランごとの保険料は以下のとおりです。
              <br />
              保険期間開始日時点の被保険者数によっては、団体割引率が変わり保険料が変更となりますので、あらかじめご了承ください。
              <img
                className="group-discount mgt36"
                src={state.hasPaymentsType
                  ? "/resources/images/group_discount_has_payments.png"
                  : "/resources/images/group_discount.jpg"}
                alt="団体割引別保険料"
              />
            </h4>
            <button
              className="button-common-blue mgt36"
              onClick={() => {
                setIsAmountInfoModalOpen(false);
              }}
            >
              閉じる
            </button>
          </div>
        </BaseModal>
      </section>
    </article>
  );
};

export default OccupationPlan;
