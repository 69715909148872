import { ParseNameEnum } from "../enum/Enum";

const ParsedUtil = {
  doParsing: (type: ParseNameEnum, value: any) => {
    let changedValue;
    switch (type) {
      case ParseNameEnum.JSON:
        changedValue = ParsedUtil.parsedToJson(value);
        break;
      case ParseNameEnum.NUMBER:
        changedValue = ParsedUtil.parsedToNumber(value);
        break;
      case ParseNameEnum.BOOLEAN:
        changedValue = ParsedUtil.parsedToBoolean(value);
        break;
      case ParseNameEnum.DATE:
        changedValue = ParsedUtil.parsedToDate(value);
    }
    return changedValue;
  },
  parsedToJson: (value: string) => {
    return JSON.parse(value) || undefined;
  },
  parsedToNumber: (value: any) => {
    return Number.parseInt(value);
  },
  parsedToBoolean: (value: any) => {
    return Boolean(value).valueOf();
  },
  parsedToDate: (value: any) => {
    var reISO =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    //eslint-disable-next-line
    var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

    if (typeof value === "string") {
      var validation = reISO.exec(value);
      if (validation) {
        return new Date(value);
      }
      validation = reMsAjax.exec(value);
      if (validation) {
        var b = validation[1].split(/[-+,.]/);
        return new Date(b[0] ? +b[0] : 0 - +b[1]);
      }
    } else if (!isNaN(value) && value instanceof Date) {
      return value;
    } else if (isNaN(value)) {
      return new Date(value);
    }
    return undefined;
  },
};
export default ParsedUtil;
