import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const InfoMessage = (props: Props) => {
  return (
    <>
      <img
        src="/resources/images/ico_info_sp.png"
        alt="icon-info"
        className="icon-info"
      />
      {props.children}
    </>
  );
};

export default InfoMessage;